import { jsx as _jsx } from "react/jsx-runtime";
import { useTheme } from '../../../../../plugins/styled';
import { RfpMenuTabs } from './styles';
const RfpMenu = ({ tabs, activeTab, handleTabClick }) => {
    const theme = useTheme();
    return (_jsx(RfpMenuTabs, { activeKey: activeTab, onTabClick: handleTabClick, items: tabs, style: {
            fontWeight: theme.fontWeights.regular,
            fontSize: theme.fonts.buttonC,
            lineHeight: 'normal',
        }, className: "rfp-menu" }));
};
export default RfpMenu;
