import styled from '../../../../plugins/styled';
export const MessageCardContainer = styled.div `
  padding: 12px 20px;
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.primary};
  display: flex;
  gap: 19px;
  flex-direction: row;
  @media (max-width: ${(props) => { var _a, _b; return (_b = (_a = props.theme) === null || _a === void 0 ? void 0 : _a.breakpoints) === null || _b === void 0 ? void 0 : _b.tablet; }}px) {
    flex-direction: column;
    align-items: center;
  }
`;
