import OnBoardingProvider, {
  OnBoardingStepTypes,
} from '@hellodarwin/core/lib/context/onboarding-provider';
import { PartnerTranslations } from '@hellodarwin/core/lib/features/entities/translations-entities';
import useApolloClient from '@hellodarwin/core/lib/features/hooks/use-appollo-client';
import AppProvider from '@hellodarwin/core/lib/features/providers/app-data-provider';
import AppTranslationsProvider from '@hellodarwin/core/lib/features/providers/app-translations-provider';
import Auth0ProviderWithNavigate, {
  OauthOptions,
} from '@hellodarwin/core/lib/features/providers/auth0-provider-with-navigate';
import BreadcrumbsProvider from '@hellodarwin/core/lib/features/providers/breadcrumbs-provider';
import { LocaleProvider } from '@hellodarwin/core/lib/features/providers/locale-provider';
import PlatformProvider from '@hellodarwin/core/lib/features/providers/platform-provider';
import AppRouterProvider from '@hellodarwin/core/lib/features/providers/router-provider';
import { UTMProvider } from '@hellodarwin/core/lib/features/providers/utm-provider';
import { ApolloProvider } from '@hellodarwin/core/lib/plugins/apollo';
import { AxiosError } from '@hellodarwin/core/lib/plugins/axios';
import { I18nextProvider } from '@hellodarwin/core/lib/plugins/i18n/provider';
import { ThemeProvider } from '@hellodarwin/core/lib/plugins/styled';
import theme from '@hellodarwin/core/lib/theme';
import getAntdTheme from '@hellodarwin/core/lib/theme/antd-theme';
import { GlobalTypographyStyling } from '@hellodarwin/core/lib/theme/global-style';
import ConfigProvider, { ThemeConfig } from 'antd/es/config-provider';
import { createClient } from 'contentful';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom/client';
import TagManager from 'react-gtm-module';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import PartnerApp from './PartnerApp';
import { store } from './app/app-store';
import { fetchOauthOptions } from './auth0/use-auth0';
import LocationProviderWrapper from './features/providers/location-provider-wrapper';
import PartnerProfileEditionProvider from './features/providers/partner-profile-edition-provider';
import UserProvider from './features/providers/user-provider';
import i18n from './i18n';
import * as serviceWorker from './serviceWorker';

const tagManagerArgs = {
  gtmId: 'GTM-M97N8SC',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root')!);

const DefaultLayout = () => {
  const antdTheme = getAntdTheme(theme);

  const partnerTheme: ThemeConfig = {
    ...antdTheme,
    components: {
      ...antdTheme.components,
      Steps: {
        colorFillContent: '#4c0099',
        colorSplit: '#ffffff',
        controlItemBgActive: '#23d6a8',
        colorTextDescription: '#ffffff',
        colorTextLabel: '#ffffff',
        colorText: '#ffffff',
        lineWidth: 2,
      },
    },
  };

  ConfigProvider.config({ theme: partnerTheme });

  const client = useApolloClient(
    'modxx330knqj',
    'P8xozOvf7K4HtnL7xpr7Sp5aO1FV4WLyJlCquu9tPz8',
  );

  const [config, setConfig] = useState<OauthOptions>();
  const [error, setError] = useState<AxiosError>();

  useEffect(() => {
    fetchOauthOptions('partner')
      .then((config) => setConfig(config))
      .catch((err) => setError(err));
  }, []);

  const space = process.env.REACT_APP_CONTENTFUL_SPACE_ID || '';
  const accessToken = process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN || '';
  const contentfulClient = createClient({
    space,
    accessToken,
  });

  return (
    <BrowserRouter>
      <LocationProviderWrapper>
        <PlatformProvider app="provider" contentfulClient={contentfulClient}>
          <Provider store={store}>
            <ThemeProvider theme={theme}>
              <GlobalTypographyStyling />
              <ConfigProvider theme={partnerTheme}>
                <I18nextProvider i18n={i18n} defaultNS={'common'}>
                  <LocaleProvider>
                    <ApolloProvider client={client}>
                      <AppTranslationsProvider
                        translationsFiles={PartnerTranslations}
                      >
                        <UTMProvider>
                          <Auth0ProviderWithNavigate
                            config={config}
                            error={error}
                            app="provider"
                          >
                            <UserProvider>
                              <AppRouterProvider>
                                <BreadcrumbsProvider>
                                  <AppProvider>
                                    <OnBoardingProvider
                                      stepType={OnBoardingStepTypes.Partner}
                                    >
                                      <PartnerProfileEditionProvider>
                                        <PartnerApp />
                                      </PartnerProfileEditionProvider>
                                    </OnBoardingProvider>
                                  </AppProvider>
                                </BreadcrumbsProvider>
                              </AppRouterProvider>{' '}
                            </UserProvider>
                          </Auth0ProviderWithNavigate>
                        </UTMProvider>
                      </AppTranslationsProvider>
                    </ApolloProvider>
                  </LocaleProvider>
                </I18nextProvider>
              </ConfigProvider>
            </ThemeProvider>
          </Provider>
        </PlatformProvider>
      </LocationProviderWrapper>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <DefaultLayout />
  </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
