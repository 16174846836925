import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ServerStack = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.30769 19.2308H25.6923M6.30769 19.2308C5.16522 19.2308 4.06954 18.7769 3.26169 17.9691C2.45384 17.1613 2 16.0656 2 14.9231M6.30769 19.2308C5.16522 19.2308 4.06954 19.6846 3.26169 20.4925C2.45384 21.3003 2 22.396 2 23.5385C2 24.681 2.45384 25.7766 3.26169 26.5845C4.06954 27.3923 5.16522 27.8462 6.30769 27.8462H25.6923C26.8348 27.8462 27.9305 27.3923 28.7383 26.5845C29.5462 25.7766 30 24.681 30 23.5385C30 22.396 29.5462 21.3003 28.7383 20.4925C27.9305 19.6846 26.8348 19.2308 25.6923 19.2308M25.6923 19.2308C26.8348 19.2308 27.9305 18.7769 28.7383 17.9691C29.5462 17.1613 30 16.0656 30 14.9231M2 14.9231C2 13.7806 2.45384 12.685 3.26169 11.8771C4.06954 11.0693 5.16522 10.6154 6.30769 10.6154H25.6923C26.8348 10.6154 27.9305 11.0693 28.7383 11.8771C29.5462 12.685 30 13.7806 30 14.9231M2 14.9231C2 13.525 2.45346 12.1646 3.29231 11.0462L7.00697 6.09233C7.45838 5.49046 8.04372 5.00195 8.71663 4.66549C9.38955 4.32903 10.1316 4.15387 10.8839 4.15387H21.1161C22.641 4.15387 24.0769 4.87182 24.993 6.09233L28.7077 11.0462C29.5465 12.1646 30 13.525 30 14.9231M25.6923 23.5385H25.7038V23.55H25.6923V23.5385ZM25.6923 14.9231H25.7038V14.9346H25.6923V14.9231ZM21.3846 23.5385H21.3961V23.55H21.3846V23.5385ZM21.3846 14.9231H21.3961V14.9346H21.3846V14.9231Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ServerStack;
