import styled from '../../../../plugins/styled';
import { media } from '../../../../theme/mixins/responsive';
export const ProfileFormContainer = styled.div `
  max-width: ${({ theme }) => theme.caps.normal}px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-inline: auto;
`;
export const ProfileFormContent = styled.div `
  display: flex;
  flex-direction: column;
  gap: 54px;

  .ant-form-item {
    &:not(.ant-form-item-vertical) {
      & > .ant-form-item-row {
        gap: 32px;
        & > .ant-form-item-control {
          flex: 2;
          align-self: center;
        }
        & > .ant-form-item-label {
          text-align: left;
          flex: 1;
          & > label {
            height: fit-content;
            .hd-form-extra {
              white-space: break-spaces;
            }
          }
        }
      }
    }
  }

  ${media.tablet `
      gap: 32px;
    .ant-form-item {
      &:not(.ant-form-item-vertical) {
        & > .ant-form-item-row {
          flex-direction: column;
          gap: 0px;
          & > .ant-form-item-control {
            flex: auto;
            width:100%;
          }
          & > .ant-form-item-label {
            text-align: left;
            flex: auto;
            & > label {
              height: fit-content;
              .hd-form-extra {
                white-space: break-spaces;
              }
            }
          }
        }
      }
    }
  `}
`;
export const ProfileFormRow = styled.div `
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;

  ${media.tablet `
       gap: 20px;
    `}
`;
export const TabletSaveContainer = styled.div `
  position: fixed;
  bottom: 0;
  left: 0;
  padding: 24px 54px;
  width: 100%;
  border-radius: 8px 8px 0 0;
  border-top: 1px solid ${({ theme }) => theme.colors.grey_5};
  border-left: 1px solid ${({ theme }) => theme.colors.grey_5};
  border-right: 1px solid ${({ theme }) => theme.colors.grey_5};
  background: ${({ theme }) => theme.colors.white_1}bb;
  backdrop-filter: blur(2px);
  z-index: 1000;
  border-box:;
`;
