var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React, { useEffect, useRef, useState, } from 'react';
import { TabBarItem, TabsBarContainer, TabsContainer } from './styles';
const Tabs = (_a) => {
    var { items, defaultActiveKey, onTabClick } = _a, props = __rest(_a, ["items", "defaultActiveKey", "onTabClick"]);
    const [activeKey, setActiveKey] = useState(defaultActiveKey !== null && defaultActiveKey !== void 0 ? defaultActiveKey : '');
    const ref = useRef(null);
    const [underlineWidth, setUnderlineWidth] = useState(0);
    const [underlineOffsetX, setUnderlineOffsetX] = useState(0);
    useEffect(() => {
        setActiveKey(defaultActiveKey !== null && defaultActiveKey !== void 0 ? defaultActiveKey : '');
    }, [defaultActiveKey]);
    useEffect(() => {
        if (!ref.current)
            return;
        const activeTab = ref.current.querySelector(`.${activeKey}`);
        if (!!activeTab) {
            setUnderlineWidth(activeTab.clientWidth);
            setUnderlineOffsetX(activeTab.offsetLeft);
        }
    }, [activeKey]);
    const handleTabClick = (activeKey, e) => {
        if (!!onTabClick) {
            onTabClick(activeKey, e);
        }
        else {
            setActiveKey(activeKey);
        }
    };
    return (_jsx(TabsContainer, Object.assign({ items: items, activeKey: activeKey, onTabClick: handleTabClick, renderTabBar: ({ onTabClick }) => (_jsx(TabsBarContainer, { "$underlineOffsetX": underlineOffsetX, "$underlineWidth": underlineWidth, ref: ref, children: React.Children.toArray(items === null || items === void 0 ? void 0 : items.map((itm) => (_jsx(TabBarItem, { onClick: (e) => onTabClick(itm.key, e), elementTheme: "body2", className: itm.key, nowrap: true, style: { letterSpacing: 0.16, paddingBottom: 14 }, children: itm.label })))) })) }, props)));
};
export default Tabs;
