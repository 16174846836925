import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import React from 'react';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import CategorySelect from './category-select';
import ServiceBudgetSelectionRow from './service-budget-selection-row';
export const ExpertiseHeader = ({ title, subtitle, footnote, size = 'medium', }) => {
    let formattedText;
    const theme = useTheme();
    switch (size) {
        case 'medium':
            formattedText = (_jsxs(_Fragment, { children: [_jsx(Typography.Title, { level: 3, elementTheme: "subtitle1", textAlign: 'left', style: { width: '100%' }, children: title }), _jsx(Typography, { elementTheme: "body2", children: subtitle })] }));
            break;
        case 'large':
            formattedText = (_jsxs(_Fragment, { children: [_jsx(Typography.Title, { level: 3, elementTheme: "h6", children: title }), _jsx(Typography, { elementTheme: "body2", children: subtitle })] }));
            break;
    }
    return (_jsxs(Div, { flex: 'column', style: { marginBottom: 32 }, gap: 4, children: [formattedText, footnote && (_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_2, children: footnote }))] }));
};
const ExpertiseForm = ({ selectedTags }) => {
    const { t } = useTranslation();
    const { functions } = useProfileEdition();
    const toggleCategory = async (category) => {
        !!functions.toggleCategoryTag &&
            (await functions.toggleCategoryTag(category));
    };
    const toggleService = async (service) => {
        !!functions.toggleServiceTag &&
            (await functions.toggleServiceTag({ service }));
    };
    const toggleSpecialty = async (specialty) => {
        !!functions.toggleSpecialtyTag &&
            (await functions.toggleSpecialtyTag({ specialty }));
    };
    const serviceBudgetSelectionRows = React.Children.toArray(selectedTags.selectedServices.map((tag, index) => {
        if (tag.isSelected) {
            return _jsx(ServiceBudgetSelectionRow, { tag: tag }, index);
        }
        return _jsx(_Fragment, {});
    }));
    return (_jsx(Form, { children: _jsxs(Div, { flex: "column", gap: 56, children: [_jsx(CategorySelect, { selectedCategories: selectedTags.selectedCategories.map((category) => category.label), categoriesOptions: selectedTags.categories.map((category) => category.label), toggleCategory: toggleCategory }), _jsxs("div", { children: [_jsx(ExpertiseHeader, { title: t(`provider-profile|expertise.service.title`), subtitle: t(`expertise|service.help`), footnote: t(`expertise|service.footnote`) }), _jsxs(Div, { flex: "column", gap: 16, children: [_jsx(Form.Item, { validateStatus: selectedTags.selectedServices.length > 0 ? 'success' : 'error', help: selectedTags.selectedServices.length > 0
                                        ? null
                                        : t('expertise|service.validation.error'), children: _jsx(Select, { mode: 'multiple', style: { width: '100%' }, allowClear: true, placeholder: t(`expertise|service.select.placeholder`), value: selectedTags.selectedServices.map((tag) => tag.tag), onSelect: (e) => toggleService(e), onDeselect: (e) => toggleService(e), defaultValue: selectedTags.selectedServices.map((tag) => tag.tag), size: "large", children: React.Children.toArray(selectedTags.selectedCategories.map((category, index) => (_jsx(Select.OptGroup, { label: t(`tags|${category.label}`), children: React.Children.toArray(category.services.map((service) => {
                                                return (_jsx(Select.Option, { value: service.tag, children: t(`tags|${service.label}`) }, service.tag));
                                            })) }, index)))) }) }), _jsx(Div, { flex: "column", gap: 8, children: serviceBudgetSelectionRows })] })] }), _jsxs("div", { children: [_jsx(ExpertiseHeader, { title: t(`provider-profile|expertise.specialty.title`), subtitle: t(`expertise|specialty.subtitle`) }), _jsx(Form.Item, { validateStatus: selectedTags.selectedSpecialties.length > 0 ? 'success' : 'error', help: selectedTags.selectedSpecialties.length > 0
                                ? null
                                : t('expertise|specialty.validation.error'), children: _jsx(Select, { mode: 'multiple', allowClear: true, placeholder: t(`expertise|specialty.select.placeholder`), value: selectedTags.selectedSpecialties.map((tag) => tag.tag), onSelect: (e) => toggleSpecialty(e), onDeselect: (e) => toggleSpecialty(e), defaultValue: selectedTags.selectedSpecialties.map((tag) => tag.tag), style: { width: '100%' }, size: "large", children: React.Children.toArray(selectedTags.selectedCategories.map((category, index) => (_jsx(Select.OptGroup, { label: t(`tags|${category.label}`), children: React.Children.toArray(category.specialties.map((specialty) => (_jsx(Select.Option, { value: specialty.tag, children: t(`tags|${specialty.label}`) }, specialty.tag)))) }, index)))) }) })] })] }) }));
};
export default ExpertiseForm;
