import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import Select from 'antd/es/select';
import { useEffect } from 'react';
import SingleLanguage from '../../../../features/enums/single-language';
import { useTranslation } from '../../../../plugins/i18n';
import ContainerFullWidthHeader from '../../../common/container/container-header';
import Div from '../../../common/div';
import { Tablet } from '../../../common/Media';
import Typography from '../../../common/typography';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import validateEmail from '../../utils/validate-email';
import ProfileFormFooter from './profile-form-footer';
import { ProfileFormContainer, ProfileFormContent, ProfileFormRow, } from './styles';
const ProfileContactForm = ({ contact, handleSave, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const languageOptions = [
        {
            value: SingleLanguage.French,
            label: t(`language.${SingleLanguage.French}`),
        },
        {
            value: SingleLanguage.English,
            label: t(`language.${SingleLanguage.English}`),
        },
    ];
    const handleReset = () => {
        form.setFieldsValue(contact);
    };
    useEffect(() => {
        handleReset();
    }, [contact]);
    const handleFinish = () => {
        form.submit();
    };
    return (_jsxs(ProfileFormContainer, { children: [_jsx(ContainerFullWidthHeader, { children: _jsxs(Div, { flex: "row", gap: 32, align: "center", justify: "space-between", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h6", children: t('profile|profileForm.contact.title') }), _jsx(Tablet, { direction: "up", children: _jsx(ProfileFormFooter, { handleFinish: handleFinish, handleReset: handleReset, isSmall: true }) })] }) }), _jsx(Div, { flex: "column", gap: 28, children: _jsx(Form, { form: form, layout: 'horizontal', name: 'profile-contact-form', initialValues: contact, onFinish: handleSave, children: _jsx(ProfileFormContent, { children: _jsxs(ProfileFormRow, { children: [_jsx(FormItem, { style: { flex: 3 }, name: "name", rules: [
                                        {
                                            required: true,
                                            message: t('profile|profileForm.contact.validation.name'),
                                        },
                                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.contact.labels.name') }), children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.contact.placeholder.name') }) }), _jsx(FormItem, { name: "phone", label: _jsx(FormLabel, { label: t('profile|profileForm.contact.labels.phone') }), children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.contact.placeholder.phone') }) }), _jsx(FormItem, { name: "email", rules: [
                                        {
                                            required: true,
                                            message: t('profile|profileForm.contact.validation.email'),
                                            validator: validateEmail(t('profile|profileForm.contact.validation.email')),
                                        },
                                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.contact.labels.email'), extra: t('profile|profileForm.contact.extra.email') }), children: _jsx(Input, { size: 'large', type: "email", placeholder: t('profile|profileForm.contact.placeholder.email') }) }), _jsx(FormItem, { name: "preferred_language", rules: [
                                        {
                                            required: true,
                                            message: t('profile|profileForm.contact.validation.preferredLanguage'),
                                        },
                                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.contact.labels.preferredLanguage'), extra: t('profile|profileForm.contact.extra.preferredLanguage') }), children: _jsx(Select, { options: languageOptions, size: "large", placeholder: t('profile|profileForm.contact.labels.preferredLanguage') }) })] }) }) }) }), _jsx(Tablet, { direction: "down", children: _jsx(ProfileFormFooter, { handleFinish: handleFinish, handleReset: handleReset, isSmall: true }) })] }));
};
export default ProfileContactForm;
