import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Dropdown from '../../../common/dropdown';
import Typography from '../../../common/typography';
const RfpSingleTile = ({ title, subtitle, className, children, style, dropdownMenuItems, }) => {
    const theme = useTheme();
    return (_jsxs(Div, { backgroundColor: theme.colors.white_1, borderColor: theme.colors.primary, style: Object.assign({ padding: 24, height: '100%' }, style), className: className, flex: "column", gap: 16, children: [(subtitle || title || dropdownMenuItems) && (_jsxs(Div, { flex: "column", gap: 8, children: [(title || dropdownMenuItems) && (_jsxs(Div, { flex: "row", align: "center", justify: "space-between", children: [title && (_jsx(Typography, { elementTheme: "subtitle2", color: theme.colors.primary, children: title })), !!dropdownMenuItems && (_jsx(Dropdown, { items: dropdownMenuItems, placement: "bottomRight", dropdownRender: (menu) => {
                                    if (React.isValidElement(menu)) {
                                        const node = menu;
                                        return React.cloneElement(node, {
                                            style: Object.assign(Object.assign({}, node.props.style), { border: `1px solid ${theme.colors.primary}` }),
                                        });
                                    }
                                    return menu;
                                } }))] })), !!subtitle && (_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_2, children: subtitle }))] })), children] }));
};
export default RfpSingleTile;
