import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import ProfileEditModal from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import ProviderProfile from '@hellodarwin/core/lib/components/provider/profile';
import {
  ProfileModalProps,
  TeamMember,
} from '@hellodarwin/core/lib/features/entities';
import ProfileBlocks from '@hellodarwin/core/lib/features/enums/profile-blocks';
import parse from '@hellodarwin/core/lib/features/helpers/parse';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import Select from 'antd/es/select';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  clearCategoriesWithSelectedTags,
  fetchLocalizedCategoriesWithSelectedTags,
  fetchRfpTags,
  selectCategoriesWithSelectedTags,
} from 'src/features/api/slices/tags-slice';
import { useAppDispatch, useAppSelector } from '../app/app-hooks';
import {
  selectProfileModal,
  toggleProfileModal,
} from '../features/api/slices/global-slice';
import {
  fetchPortfolios,
  fetchProfile,
  fetchTeamMembers,
  selectPortfolioById,
  selectPortfolios,
  selectProfile,
  selectTeamMembers,
} from '../features/api/slices/profile-slice';
import {
  fetchReviews,
  selectAllReviews,
} from '../features/api/slices/reviews-slice';
import {
  fetchWonRfps,
  selectAllRfp,
  selectRfpsLoading,
} from '../features/api/slices/rfp-slice';
import { usePartnerApi } from '../features/api/use-partner-api';

type ProfileState = 'edit' | 'website' | 'client';
const ProviderProfilePreviewPage = ({
  defaultState = 'edit',
}: {
  defaultState?: ProfileState;
}) => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const { t } = useTranslation();
  const { selectedLocale } = useLocale();

  const provider = useAppSelector(selectProfile);
  const teamMembers = useAppSelector(selectTeamMembers);
  const rfp = useAppSelector(selectAllRfp);
  const rfpLoading = useAppSelector(selectRfpsLoading);
  const portfolios = useAppSelector(selectPortfolios);
  const reviews = useAppSelector(selectAllReviews);
  const categoriesWithSelectedTags = useAppSelector(
    selectCategoriesWithSelectedTags,
  );
  const [members, setMembers] = useState<TeamMember[]>(teamMembers);
  // Fetch profile-related data
  useEffect(() => {
    const fetchData = async () => {
      dispatch(fetchProfile({ api }));
      dispatch(fetchTeamMembers({ api }));

      await dispatch(fetchPortfolios({ api }));

      dispatch(fetchWonRfps({ api }));
      dispatch(fetchReviews({ api }));
      dispatch(fetchRfpTags({ api }));
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMembers(teamMembers);
  }, [teamMembers]);

  const handleProfileModal = (props: ProfileModalProps) => {
    dispatch(toggleProfileModal(props));
  };

  //MODAL VARIABLES
  const modal = useAppSelector(selectProfileModal);
  const handleCloseModal = () => {
    dispatch(
      toggleProfileModal({ open: false, targetId: undefined, type: undefined }),
    );
  };

  const activePortfolio = useAppSelector((state) =>
    selectPortfolioById(state, modal.targetId || ''),
  );

  useEffect(() => {
    dispatch(clearCategoriesWithSelectedTags());

    if (activePortfolio) {
      dispatch(
        fetchLocalizedCategoriesWithSelectedTags({
          api,
          additionalTags: activePortfolio.tags,
        }),
      );
    } else {
      dispatch(
        fetchLocalizedCategoriesWithSelectedTags({
          api,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePortfolio]);

  const navigate = useNavigate();
  const handleState = (newState: ProfileState) => {
    navigate(`/profile/${newState}`);
  };
  const handleRedirectToSettings = () => navigate(`/settings/company`);
  const handleRedirectToExpertise = () => navigate(`/expertise`);

  return (
    <>
      <PageLayout
        app="provider"
        title=""
        className="partner-profile-preview-page"
        actions={
          <Select
            options={[
              {
                label: parse(t('provider-profile|viewTypes.edit')),
                value: 'edit',
              },
              {
                label: parse(t('provider-profile|viewTypes.client')),
                value: 'client',
              },
              {
                label: parse(t('provider-profile|viewTypes.website')),
                value: 'website',
              },
            ]}
            size="large"
            style={{ width: 250 }}
            onChange={handleState}
            value={defaultState}
          />
        }
        breadcrumbs={[
          {
            breadcrumbName: t('navigation.home'),
            path: '/',
          },
          {
            breadcrumbName: t('navigation.profile'),
            path: '/profile',
          },
          {
            breadcrumbName: t(`navigation.${defaultState}`),
          },
        ]}
      >
        <Div flex="column" align="center">
          <ProviderProfile
            provider={provider}
            state={defaultState}
            reviews={reviews}
            portfolios={portfolios}
            teamMembers={members}
            setTeamMembers={setMembers}
            rfps={{ isLoading: rfpLoading, rfps: rfp }}
            toggleEditModal={handleProfileModal}
            handleRedirectToSettings={handleRedirectToSettings}
            handleRedirectToExpertise={handleRedirectToExpertise}
            sections={
              defaultState === 'website'
                ? [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.About,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Rfp,
                    ProfileBlocks.Team,
                    ProfileBlocks.Contact,
                  ]
                : [
                    ProfileBlocks.FastInfos,
                    ProfileBlocks.Presentation,
                    ProfileBlocks.Portfolios,
                    ProfileBlocks.About,
                    ProfileBlocks.Reviews,
                    ProfileBlocks.Team,
                    ProfileBlocks.Expertise,
                    ProfileBlocks.Contact,
                  ]
            }
            locale={selectedLocale}
          />
        </Div>
      </PageLayout>
      <ProfileEditModal
        modal={modal}
        handleCancel={handleCloseModal}
        teamMembers={members}
        setTeamMembers={setMembers}
        activePortfolio={activePortfolio}
        categoriesWithSelectedTags={categoriesWithSelectedTags}
      />
    </>
  );
};

export default ProviderProfilePreviewPage;
