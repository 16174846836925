import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '../../../../plugins/i18n';
import Div from '../../../common/div';
import ModalWarning from '../../../common/hd-modal/layouts/modal-with-warning';
import Typography from '../../../common/typography';
const OnlyCanadianWarningModal = ({ open, handleClose, }) => {
    const { t } = useTranslation();
    return (_jsx(ModalWarning, { open: open, handleCancel: handleClose, children: _jsxs(Div, { flex: "column", gap: 8, align: "center", children: [_jsx(Typography.Title, { textAlign: "center", level: 2, elementTheme: "h5", children: t('profile|country.notCanadaWarningTitle') }), _jsx(Typography, { textAlign: "center", children: t('profile|country.notCanadaWarningSubtitle') })] }) }));
};
export default OnlyCanadianWarningModal;
