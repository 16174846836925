import styled, {
  css,
  DefaultTheme,
} from '@hellodarwin/core/lib/plugins/styled';

export const CategorySelectedStyle = (theme: DefaultTheme) => css`
  background-color: ${theme.colors.beige_2};
  border: 2px solid ${theme.colors.purple_2};
`;

export const CategoryHoverStyle = (theme: DefaultTheme) => css`
  background-color: ${theme.colors.purple_5};
  border: 2px solid ${theme.colors.purple_2};
`;

export const CategoryContainer = styled.div<{ $selected?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-sizing: border-box;

  padding: 16px 8px;
  cursor: pointer;
  border-radius: 0;

  height: 160px;
  position: relative;
  transition: $transition;
  border-radius: 4px;
  box-shadow: ${({ theme }) => theme.appearance.box_shadow};
  ${({ $selected, theme }) =>
    $selected
      ? CategorySelectedStyle(theme)
      : css`
          border: 2px solid ${theme.colors.primary};
          background-color: ${theme.colors.beige_4};

          &:hover {
            box-shadow: ${theme.appearance.box_shadow_hover};
            transform: translateY(-2px);
            ${CategoryHoverStyle(theme)}
          }
        `};
`;

export const CategoryIconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99px;
  background: ${({ theme }) => theme.colors.white_1};
  border: 1px solid ${({ theme }) => theme.colors.grey_1};
  aspect-ratio: 1;
  padding: 16px;
  color: ${({ theme }) => theme.colors.grey_1};
`;
