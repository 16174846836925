import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from '../../plugins/i18n';
import theme from '../../theme';
import Button from '../common/button';
import Div from '../common/div';
const OnboardingFooter = ({ handleNext, handlePrev, nextDisabled, isLoading, lastStep, }) => {
    const { t } = useTranslation();
    return (_jsx(Div, { flex: "column", gap: 24, style: { marginTop: 24 }, children: _jsxs(Div, { flex: 'row', justify: 'center', align: 'center', alignSelf: 'flex-end', gap: 10, children: [handlePrev !== undefined && (_jsx(Button, { defaultStyle: theme.colors.transparent, withArrowLeft: true, size: "small", onClick: handlePrev, children: t('button.back') })), _jsx(Button, { defaultStyle: theme.colors.purple_1, withArrowRight: true, loading: isLoading, onClick: handleNext, size: "small", disabled: nextDisabled, children: lastStep ? t('button.submit') : t('button.next') })] }) }));
};
export default OnboardingFooter;
