import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const BusinessDevelopment = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.06 13.08C25.06 11.94 24.56 11.66 23.66 10.85C22.76 10.04 21.54 9.59001 20.26 9.59001H18.13C17.71 9.59001 17.3 9.44001 17 9.17001C16.7 8.90001 16.53 8.54001 16.53 8.15001V6.23001C16.53 5.09001 16.02 3.99001 15.12 3.18001C14.22 2.37001 13 1.92001 11.72 1.92001H9.05M7.18 21H15.13M25.06 13.42C25.06 10.37 23.71 7.45001 21.31 5.29001C18.91 3.13001 15.65 1.92001 12.25 1.92001H5.32C4.44 1.92001 3.72 2.56001 3.72 3.36001V25.39C3.72 26.18 4.44 26.83 5.32 26.83H15.35M7.12 16.46H16.74M27.63 26C27.07 24.98 26.18 24.17 25.11 23.7C24.04 23.24 22.84 23.14 21.71 23.43C20.58 23.72 19.58 24.38 18.86 25.3C18.14 26.22 17.76 27.36 17.76 28.53V28.62C19.3 29.55 21.1 30.08 23.02 30.08C24.87 30.08 26.69 29.58 28.28 28.62V28.53C28.28 27.61 28.04 26.74 27.63 25.99V26ZM25.81 18.22C25.81 18.96 25.52 19.67 24.99 20.19C24.47 20.71 23.76 21.01 23.02 21.01C22.28 21.01 21.57 20.72 21.05 20.19C20.53 19.67 20.23 18.96 20.23 18.22C20.23 17.48 20.52 16.77 21.05 16.25C21.57 15.73 22.28 15.43 23.02 15.43C23.76 15.43 24.47 15.72 24.99 16.25C25.51 16.77 25.81 17.48 25.81 18.22Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BusinessDevelopment;
