import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import TabArrow from '@hellodarwin/icons/dist/icons/TabArrow';
import Form from 'antd/es/form';
import Input from 'antd/es/input/Input';
import notification from 'antd/es/notification';
import Select from 'antd/es/select';
import dayjs from 'dayjs';
import React, { Suspense, lazy, useEffect, useState } from 'react';
import Language from '../../../../features/enums/language';
import SingleLanguage from '../../../../features/enums/single-language';
import prefixHTTPS from '../../../../features/helpers/prefix-https';
import { usePlatform } from '../../../../features/providers/platform-provider';
import { useTranslation } from '../../../../plugins/i18n';
import Collapse from '../../../common/Collapse';
import { Tablet } from '../../../common/Media';
import ContainerFullWidthHeader from '../../../common/container/container-header';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ProfileLogo from '../../../provider/profile/profile-assets/profile-logo';
import AddressAutocompleteInput from '../../form-groups/adress-autocomplete-input';
import FormItem from '../../form-layouts/form-item';
import FormLabel from '../../form-layouts/form-label';
import { annualRevenue, companySize } from '../../utils/companyInfos';
import validateWebsite from '../../utils/validate-website';
import ProfileFormFooter from './profile-form-footer';
import { ProfileFormContainer, ProfileFormContent, ProfileFormRow, } from './styles';
const DatePicker = lazy(() => import('antd/es/date-picker'));
const ProfileCompanyForm = ({ company, handleSave, isPartner, toggleEditModal, industrySectors, industrySubsectors, }) => {
    const { t } = useTranslation();
    const [form] = Form.useForm();
    const [externalIsOpen, setExternalOpen] = useState([
        'external_links',
    ]);
    const [filteredSubsectors, setFilteredSubsectors] = useState([]);
    const languageOptionsProfile = [
        {
            value: SingleLanguage.French,
            label: t(`language.${SingleLanguage.French}`),
        },
        {
            value: SingleLanguage.English,
            label: t(`language.${SingleLanguage.English}`),
        },
    ];
    const languageOptionsProject = [
        {
            value: SingleLanguage.French,
            label: t(`language.${SingleLanguage.French}`),
        },
        {
            value: SingleLanguage.English,
            label: t(`language.${SingleLanguage.English}`),
        },
        {
            value: Language.Any,
            label: t(`language.any`),
        },
    ];
    const handleChange = () => {
        const selectedIndustrySectors = form.getFieldValue('industry_sector') || [];
        const currentSubsectors = form.getFieldValue('industry_subsector') || [];
        const newFilteredSubsectors = industrySubsectors
            .filter((item) => selectedIndustrySectors.includes(item.parent))
            .map((item) => ({
            label: `${item.id} - ${item.class_title}`,
            value: item.id,
        }))
            .sort((a, b) => a.value.localeCompare(b.value));
        setFilteredSubsectors(newFilteredSubsectors);
        const validSubsectors = currentSubsectors.filter((subsectorId) => newFilteredSubsectors.some((subsector) => subsector.value === subsectorId));
        form.setFieldsValue({
            industry_subsector: validSubsectors,
        });
    };
    useEffect(() => {
        handleChange();
    }, [industrySectors]);
    const handleReset = () => {
        form.resetFields();
        form.setFieldsValue(company);
    };
    useEffect(() => {
        handleReset();
    }, [company]);
    const CompanyContactDetails = () => {
        return (_jsxs(Div, { children: [_jsx(Typography.Title, { level: 2, elementTheme: "h6", fitContent: true, nowrap: true, style: { paddingBottom: 32, paddingTop: 24 }, children: t('profile|profileForm.company.labels.companyContactDetails') }), _jsxs(ProfileFormRow, { children: [_jsx(FormItem, { name: "email", rules: [
                                {
                                    required: true,
                                    message: t('profile|profileForm.company.validation.email'),
                                },
                            ], label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.email'), extra: t('profile|profileForm.company.extra.email') }), style: { flex: 2 }, children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.placeholder.email') }) }), _jsx(FormItem, { name: "phone", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.phone'), extra: t('profile|profileForm.company.extra.phone') }), style: { flex: 1 }, children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.placeholder.phone') }) }), !isPartner && _jsx(LanguageInputs, {})] }), isPartner && _jsx(LanguagesRow, {})] }));
    };
    const ExternalLinks = () => {
        const SocialItem = ({ label, name, placeholder, }) => {
            return (_jsx(FormItem, { label: _jsx(FormLabel, { label: label }), name: name, normalize: (value) => {
                    const valueWithHttps = prefixHTTPS(value);
                    return value.length && !'https://'.includes(valueWithHttps)
                        ? valueWithHttps
                        : '';
                }, rules: [
                    {
                        validator: validateWebsite({
                            message: t('profile|profileForm.company.validation.invalid_website'),
                            isRequired: false,
                        }),
                    },
                ], children: _jsx(Input, { allowClear: true, placeholder: placeholder, size: 'large' }) }));
        };
        return (_jsx(Collapse, { style: { marginTop: 32 }, ghost: true, noPadding: true, activeKey: externalIsOpen, onChange: setExternalOpen, ExpandIcon: ({ isActive }) => _jsx(TabArrow, { down: !isActive, size: 12 }), items: [
                {
                    title: (_jsx(Typography.Title, { level: 2, elementTheme: "h6", fitContent: true, nowrap: true, children: t('profile|profileForm.company.labels.externalLinks') })),
                    children: (_jsxs(Div, { flex: "column", gap: 12, style: { marginTop: 24 }, children: [_jsx(SocialItem, { label: "Facebook", name: "facebook", placeholder: "https://www.facebook.com/match.hellodarwin" }), _jsx(SocialItem, { label: "Twitter", name: "twitter", placeholder: "https://twitter.com/say_hellodarwin" }), _jsx(SocialItem, { label: "Linkedin", name: "linked_in", placeholder: "https://www.linkedin.com/company/hellodarwin/" }), _jsx(SocialItem, { label: "Instagram", name: "instagram", placeholder: "https://www.instagram.com/hellodarwin.match/" }), _jsx(SocialItem, { label: "Pinterest", name: "pinterest", placeholder: "https://www.pinterest.com/hellodarwin.match" }), _jsx(SocialItem, { label: "Tiktok", name: "tiktok", placeholder: "https://www.tiktok.com/@hellodarwin.match" }), _jsx(SocialItem, { label: "Youtube", name: "youtube", placeholder: "https://www.youtube.com/@hellodarwin8652" })] })),
                    id: 'external_links',
                },
            ] }));
    };
    const BusinessDetails = () => {
        var _a;
        const { app } = usePlatform();
        return (_jsxs(ProfileFormRow, { children: [_jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.logo') }), children: _jsx(ProfileLogo, { name: company.name, logo: (_a = company.logo) !== null && _a !== void 0 ? _a : '', isEditable: true, toggleEditModal: toggleEditModal, style: { marginRight: 10 }, type: app === 'client' ? 'client' : 'provider' }) }), _jsx(FormItem, { style: { flex: 3 }, name: "name", rules: [
                        {
                            required: true,
                            message: t('profile|profileForm.company.validation.name'),
                        },
                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.name') }), children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.placeholder.name') }) }), _jsx(FormItem, { style: { flex: 2 }, name: "website", rules: [
                        {
                            required: true,
                            validator: validateWebsite({
                                message: t('profile|profileForm.company.validation.website'),
                                isRequired: true,
                            }),
                            message: t('profile|profileForm.company.validation.website'),
                        },
                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.website') }), normalize: (value) => prefixHTTPS(value), children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.placeholder.website'), allowClear: true }) }), isPartner && (_jsx(FormItem, { style: { flex: 3 }, name: "tagline", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.tagline') }), children: _jsx(Input, { size: 'large', placeholder: t('profile|profileForm.company.placeholder.tagline') }) })), _jsx(FormItem, { style: { flex: 1 }, name: "size", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.size') }), children: _jsx(Select, { placeholder: "Select option", size: "large", children: React.Children.toArray(companySize.map((option) => (_jsx(Select.Option, { value: option.value, children: option.label }, option.value)))) }) }), _jsx(FormItem, { label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.annualRevenue') }), name: "annual_revenue", style: { flex: 1 }, children: _jsx(Select, { placeholder: t('profile|profileForm.company.placeholder.annualRevenue'), size: "large", children: React.Children.toArray(annualRevenue.map((option) => (_jsx(Select.Option, { value: option.value, children: option.label }, option.value)))) }) }), _jsx(Suspense, { fallback: _jsx(_Fragment, {}), children: _jsx(FormItem, { name: "founded_at", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.foundedAt') }), style: { flex: 1 }, getValueFromEvent: (onChange) => (onChange ? dayjs(onChange) : ''), getValueProps: (value) => ({
                            value: value ? dayjs(value) : null,
                        }), children: _jsx(DatePicker, { size: "large", picker: "year", allowClear: true, style: { width: '100%' } }) }) }), _jsx(AddressAutocompleteInput, { form: form, showManualAddress: true, provinceRequired: true }), _jsx(FormItem, { style: { flex: 1 }, name: "industry_sector", label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.industrySector') }), children: _jsx(Select, { mode: "multiple", options: industrySectors, onChange: handleChange, size: 'large' }) }), _jsx(FormItem, { name: "industry_subsector", style: { flex: 1 }, label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.industrySubsector') }), children: _jsx(Select, { mode: "multiple", size: 'large', options: filteredSubsectors, notFoundContent: t('profile|profileForm.company.extra.industrySubsector') }) })] }));
    };
    const LanguageInputs = () => {
        return (_jsxs(_Fragment, { children: [_jsx(FormItem, { style: { flex: 1 }, name: "profile_language", rules: [
                        {
                            required: true,
                            message: t('profile|profileForm.company.validation.profileLanguage'),
                        },
                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.profileLanguage'), extra: t('profile|profileForm.company.extra.profileLanguage') }), children: _jsx(Select, { options: languageOptionsProfile, size: "large", placeholder: t('profile|profileForm.company.labels.profileLanguage') }) }), isPartner && (_jsx(FormItem, { style: { flex: 1 }, name: "project_language", rules: [
                        {
                            required: true,
                            message: t('profile|profileForm.company.validation.projectLanguage'),
                        },
                    ], label: _jsx(FormLabel, { label: t('profile|profileForm.company.labels.projectLanguage'), extra: t('profile|profileForm.company.extra.projectLanguage') }), children: _jsx(Select, { options: languageOptionsProject, size: "large", placeholder: t('profile|profileForm.company.labels.projectLanguage') }) }))] }));
    };
    const LanguagesRow = () => {
        return (_jsx(ProfileFormRow, { children: _jsx(LanguageInputs, {}) }));
    };
    const handleFinish = () => {
        form === null || form === void 0 ? void 0 : form.submit();
    };
    return (_jsxs(ProfileFormContainer, { children: [_jsxs(ContainerFullWidthHeader, { children: [_jsxs(Div, { flex: "row", gap: 32, align: "center", justify: "space-between", children: [_jsx(Typography.Title, { level: 2, elementTheme: "h6", children: t('profile|profileForm.company.title') }), _jsx(Tablet, { direction: "up", children: _jsx(ProfileFormFooter, { handleFinish: handleFinish, handleReset: handleReset, isSmall: true }) })] }), ' '] }), _jsx(Form, { form: form, layout: 'horizontal', name: 'cancel-project', initialValues: { company, isCanada: company.country === 'Canada' }, onFinish: handleSave, onFinishFailed: () => {
                    notification.error({
                        message: t('notification.required_fields'),
                        placement: 'top',
                    });
                }, children: _jsxs(ProfileFormContent, { children: [_jsx(BusinessDetails, {}), _jsx(CompanyContactDetails, {}), _jsx(ExternalLinks, {})] }) }), _jsx(Tablet, { direction: "down", children: _jsx(ProfileFormFooter, { handleFinish: handleFinish, handleReset: handleReset, isSmall: true }) })] }));
};
export default ProfileCompanyForm;
