import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ChatIcon = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.1538 12.7692C11.1538 12.912 11.0971 13.049 10.9961 13.15C10.8952 13.2509 10.7582 13.3077 10.6154 13.3077C10.4726 13.3077 10.3356 13.2509 10.2346 13.15C10.1337 13.049 10.0769 12.912 10.0769 12.7692C10.0769 12.6264 10.1337 12.4894 10.2346 12.3885C10.3356 12.2875 10.4726 12.2308 10.6154 12.2308C10.7582 12.2308 10.8952 12.2875 10.9961 12.3885C11.0971 12.4894 11.1538 12.6264 11.1538 12.7692ZM11.1538 12.7692H10.6154M16.5385 12.7692C16.5385 12.912 16.4817 13.049 16.3808 13.15C16.2798 13.2509 16.1428 13.3077 16 13.3077C15.8572 13.3077 15.7202 13.2509 15.6193 13.15C15.5183 13.049 15.4615 12.912 15.4615 12.7692C15.4615 12.6264 15.5183 12.4894 15.6193 12.3885C15.7202 12.2875 15.8572 12.2308 16 12.2308C16.1428 12.2308 16.2798 12.2875 16.3808 12.3885C16.4817 12.4894 16.5385 12.6264 16.5385 12.7692ZM16.5385 12.7692H16M21.9231 12.7692C21.9231 12.912 21.8663 13.049 21.7654 13.15C21.6644 13.2509 21.5274 13.3077 21.3846 13.3077C21.2418 13.3077 21.1048 13.2509 21.0039 13.15C20.9029 13.049 20.8462 12.912 20.8462 12.7692C20.8462 12.6264 20.9029 12.4894 21.0039 12.3885C21.1048 12.2875 21.2418 12.2308 21.3846 12.2308C21.5274 12.2308 21.6644 12.2875 21.7654 12.3885C21.8663 12.4894 21.9231 12.6264 21.9231 12.7692ZM21.9231 12.7692H21.3846M2 17.0913C2 19.3887 3.61251 21.3903 5.88697 21.7249C7.44779 21.9547 9.02441 22.1313 10.6154 22.2548V28.9231L16.6232 22.9167C16.9206 22.6204 17.3206 22.4497 17.7403 22.44C20.5426 22.371 23.3382 22.1322 26.1116 21.7249C28.3875 21.3903 30 19.3901 30 17.0898V8.4486C30 6.14829 28.3875 4.14809 26.113 3.81352C22.7645 3.32204 19.3844 3.07584 16 3.07691C12.5653 3.07691 9.1881 3.32819 5.88697 3.81352C3.61251 4.14809 2 6.14973 2 8.4486V17.0898V17.0913Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ChatIcon;
