import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Copy from '@hellodarwin/icons/dist/icons/Copy';
import TextArea from 'antd/es/input/TextArea';
import Segmented from 'antd/es/segmented';
import { useEffect, useRef, useState } from 'react';
import { newClientAnalyticsUser } from '../../../../features/entities';
import { copyToClipboard } from '../../../../features/helpers';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
const BadgesForm = ({ numberReviews, providerId, average, handleClipboardAnalytics, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const lightness = [
        t(`expertise|badges.LightnessType.type1`),
        t(`expertise|badges.LightnessType.type2`),
    ];
    const size = [
        t(`expertise|badges.SizeType.type1`),
        t(`expertise|badges.SizeType.type2`),
        t(`expertise|badges.SizeType.type3`),
    ];
    const language = [
        t(`expertise|badges.language.type2`),
        t(`expertise|badges.language.type1`),
    ];
    const [scriptLoaded, setScriptLoaded] = useState(false);
    const [containerHTML, setContainerHTML] = useState();
    const [sizeBadges, setSize] = useState('Large');
    const [languages, setLanguages] = useState('English');
    const [lightnesses, setlight] = useState(lightness[0]);
    useEffect(() => {
        if (scriptLoaded) {
            const badgesContainer = document.getElementById('badges-container');
            if (badgesContainer) {
                badgesContainer.dataset.color = '#ff0000';
                badgesContainer.dataset.size = sizeBadges;
                badgesContainer.dataset.lightness =
                    lightnesses == 'Blanc' || lightnesses == 'Light' ? 'Light' : 'Dark';
                badgesContainer.dataset.language =
                    languages === language[0] ? 'en' : 'fr';
                updateContainerHTML();
            }
            const badgeWidgetOptions = {
                color: '#ff0000',
                size: sizeBadges,
                lightness: lightnesses == 'Blanc' || lightnesses == 'Light' ? 'Light' : 'Dark',
                language: languages,
                rating: 5,
                numberReviews: numberReviews,
            };
            const idBadgeVerifiedElement = document.getElementById('idBadgeVerified');
            if (typeof createBadgeWidget === 'function' && idBadgeVerifiedElement) {
                const badgeWidgetHtml = createBadgeWidget(badgeWidgetOptions);
                idBadgeVerifiedElement.innerHTML = badgeWidgetHtml;
            }
            const starRatingContainer = document.createElement('div');
            starRatingContainer.classList.add('star-rating');
            for (let i = 1; i <= 5; i++) {
                const star = document.createElement('span');
                star.classList.add('star');
                if (i <= +average) {
                    star.classList.add('filled');
                    star.innerHTML = '\u2605';
                    star.style.color = lightnesses == lightness[0] ? '#2b2b2b' : 'white';
                }
                else {
                    star.classList.add('filled');
                    star.innerHTML = '\u2605';
                    star.style.color = 'gray';
                }
                starRatingContainer.appendChild(star);
                starRatingContainer.style.marginTop = '12%';
            }
            let pElement = document.createElement('p');
            pElement.style.fontSize = '85%';
            pElement.style.color = 'var(--Grey-2, #5C5C5C)';
            pElement.style.display = 'flex';
            pElement.style.marginTop = '1%';
            pElement.style.marginLeft = '0.5rem';
            pElement.style.width = '100%';
            pElement.style.height = '100%';
            let borderSpan = document.createElement('span');
            borderSpan.style.marginRight = '0.5rem';
            borderSpan.style.borderLeft = `1px solid ${lightnesses == lightness[0] ? theme.colors.grey_1 : theme.colors.white_1}`;
            let textSpan = document.createElement('span');
            if (languages === language[0]) {
                textSpan.textContent = `${numberReviews} REVIEWS`;
            }
            else {
                textSpan.textContent = `${numberReviews} AVIS`;
            }
            textSpan.style.color = lightnesses == lightness[0] ? '#2b2b2b' : 'white';
            pElement.appendChild(borderSpan);
            pElement.appendChild(textSpan);
            starRatingContainer.appendChild(pElement);
            starRatingContainer.style.display = 'flex';
            if (languages === language[0]) {
                starRatingContainer.style.marginTop = '10%';
            }
            const divReviews = document.getElementById('divReviews');
            if (divReviews) {
                const starRatingContainer1 = starRatingContainer.cloneNode(true);
                divReviews.appendChild(starRatingContainer1);
            }
        }
        else {
            const badgesContainer = document.getElementById('badges-container');
            if (badgesContainer) {
                badgesContainer.dataset.color = '#ff0000';
                badgesContainer.dataset.size = sizeBadges;
                badgesContainer.dataset.lightness = lightnesses;
                badgesContainer.dataset.language =
                    languages === language[0] ? 'en' : 'fr';
            }
            const existingBadgeWidget2 = document.getElementById('idBadgeVerified');
            existingBadgeWidget2.innerHTML = '';
            const script = document.createElement('script');
            script.src =
                'https://hellodarwin-core.s3.amazonaws.com/badgeComponent.js';
            script.async = true;
            script.onload = () => {
                const badgeWidgetOptions = {
                    color: '#ff0000',
                    size: sizeBadges,
                    lightness: lightnesses,
                    language: languages,
                    rating: 5,
                    numberReviews: numberReviews,
                };
                if (typeof createBadgeWidget === 'function') {
                    const badgeWidgetHtml = createBadgeWidget(badgeWidgetOptions);
                    const isBadgetVerified = document.getElementById('idBadgeVerified');
                    if (isBadgetVerified)
                        isBadgetVerified.insertAdjacentHTML('afterbegin', badgeWidgetHtml);
                }
                setScriptLoaded(true);
            };
            document.head.appendChild(script);
        }
    }, [sizeBadges, lightnesses, languages, numberReviews, scriptLoaded]);
    if (document.getElementById('badges-container')) {
        const htmlDiv = document.getElementById('badges-container');
        htmlDiv.dataset.provider = providerId;
    }
    const updateContainerHTML = () => {
        if (scriptLoaded) {
            const container = document.getElementById('badges-container');
            setContainerHTML(container.outerHTML);
        }
    };
    const generateScriptTagVerified = () => {
        if (!scriptLoaded) {
            return '';
        }
        const scriptContent = `${containerHTML} <script src="https://hellodarwin-core.s3.amazonaws.com/componentScriptVerified.js" async></script>
    `;
        return scriptContent;
    };
    const Header = () => {
        return (_jsxs(Div, { gap: 170, flex: "row", tablet: { flex: 'column' }, style: { display: 'flex' }, justify: "center", children: [_jsx(Option, { title: t(`expertise|badges.LayoutTitle.lightness`), children: _jsx(Segmented, { options: lightness, onChange: (val) => {
                            setlight(val);
                        }, value: lightnesses }) }), _jsx(Option, { title: t(`expertise|badges.LayoutTitle.language`), children: _jsx(Segmented, { options: language, onChange: (val) => {
                            setLanguages(val);
                        }, value: languages }) })] }));
    };
    return (_jsxs(Div, { flex: "column", gap: 20, children: [_jsx("div", { id: "badges-container" }), _jsx(Typography, { children: t(`expertise|badges.subtitle`) }), _jsx(Div, { bordersSide: "horizontal", borderColor: theme.colors.grey_5, style: {
                    padding: '1rem 0',
                }, children: _jsx(Header, {}) }), _jsx(Div, { flex: "row", gap: 20, children: _jsx(BadgeData, { id: "idBadgeVerified", title: t(`expertise|badges.verified`), scriptData: generateScriptTagVerified(), handleClipboardAnalytics: handleClipboardAnalytics }) })] }));
};
const Option = ({ title, children, style, }) => {
    return (_jsxs(Div, { style: style, flex: "column", fitContent: true, gap: 6, children: [_jsx(Typography.Title, { level: 6, fitContent: true, children: title }), children] }));
};
const BadgeData = ({ id, title, scriptData, handleClipboardAnalytics, }) => {
    const textarea = useRef(null);
    const theme = useTheme();
    const handleCopyTextarea = () => {
        var _a, _b;
        !!handleClipboardAnalytics && handleClipboardAnalytics();
        if (!!textarea.current) {
            copyToClipboard((_b = (_a = textarea.current.resizableTextArea) === null || _a === void 0 ? void 0 : _a.textArea.value) !== null && _b !== void 0 ? _b : '');
        }
        newClientAnalyticsUser;
    };
    return (_jsxs(Div, { flex: "column", gap: 20, children: [_jsx(Typography.Title, { level: 6, textAlign: "center", children: title }), _jsxs(Div, { flex: "column", gap: 20, children: [_jsx(Div, { id: id, flex: "column", align: "center", children: '' }), _jsxs(Div, { flex: "row", gap: 10, children: [_jsx(TextArea, { style: {
                                    flex: 5,
                                    resize: 'none',
                                    textAlign: 'center',
                                    paddingBottom: '5%',
                                }, ref: textarea, value: scriptData, readOnly: true }), _jsx(Button, { onClick: handleCopyTextarea, defaultStyle: theme.colors.white_1, style: { borderRadius: 4 }, headingIcon: _jsx(Copy, {}) })] })] })] }));
};
export default BadgesForm;
