import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useRef } from 'react';
import { ContainerFullWidthHeaderStyling } from './styles';
const ContainerFullWidthHeader = ({ children }) => {
    const ref = useRef(null);
    useEffect(() => {
        const observer = new IntersectionObserver(([e]) => e.target.classList.toggle('is-pinned', e.intersectionRatio < 1), { threshold: [1] });
        !!ref.current && observer.observe(ref.current);
        return () => {
            observer.disconnect();
        };
    }, [ref]);
    return (_jsx(ContainerFullWidthHeaderStyling, { ref: ref, children: children }));
};
export default ContainerFullWidthHeader;
