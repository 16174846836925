import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const DeploymentUnit = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.14 11.8L10.61 14.99V21.37L16.14 24.56L21.66 21.37V14.99L16.14 11.8ZM16.14 11.8L16.19 7.45001M6.03998 24.92L10.08 21.8M25.95 24.88L21.92 21.76M16.19 7.45001C17.173 7.45001 17.97 6.65308 17.97 5.67001C17.97 4.68695 17.173 3.89001 16.19 3.89001C15.2069 3.89001 14.41 4.68695 14.41 5.67001C14.41 6.65308 15.2069 7.45001 16.19 7.45001ZM6.40998 26.33C6.40998 27.3131 5.61304 28.11 4.62998 28.11C3.64691 28.11 2.84998 27.3131 2.84998 26.33C2.84998 25.3469 3.64691 24.55 4.62998 24.55C5.61304 24.55 6.40998 25.3469 6.40998 26.33ZM29.15 26.29C29.15 27.2731 28.353 28.07 27.37 28.07C26.3869 28.07 25.59 27.2731 25.59 26.29C25.59 25.3069 26.3869 24.51 27.37 24.51C28.353 24.51 29.15 25.3069 29.15 26.29Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DeploymentUnit;
