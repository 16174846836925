import { prefixHTTPS } from '../../../features/helpers/prefix-https';
const normalizeWebsiteURL = (value, options = { ensureProtocol: true }) => {
    if (!value)
        return '';
    try {
        let normalizedUrl = value.trim().toLowerCase();
        normalizedUrl = prefixHTTPS(normalizedUrl);
        return normalizedUrl;
    }
    catch (error) {
        return '';
    }
};
export default normalizeWebsiteURL;
