import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Delete from '@hellodarwin/icons/dist/icons/Delete';
import Breadcrumb from 'antd/es/breadcrumb/Breadcrumb';
import Checkbox from 'antd/es/checkbox/Checkbox';
import InputNumber from 'antd/es/input-number';
import Typography from 'antd/es/typography';
import { useState } from 'react';
import { useProfileEdition } from '../../../../../features/providers/profile-edition-provider';
import { useTranslation } from '../../../../../plugins/i18n';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
const ServiceBudgetSelectionRow = ({ tag }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const { functions } = useProfileEdition();
    const [isChecked, setIsChecked] = useState(tag.max_budget <= 0);
    const handleCheck = () => {
        setIsChecked((previousState) => !previousState);
    };
    const handleDescriptionChange = (text) => {
        !!functions.handleServiceChange &&
            functions.handleServiceChange({
                service: tag.tag,
                minBudget: tag.min_budget,
                maxBudget: tag.max_budget,
                description: text,
            });
    };
    const handleMaxBudgetChange = (maxBudget) => {
        var _a;
        if (maxBudget !== null) {
            !!functions.handleServiceChange &&
                functions.handleServiceChange({
                    service: tag.tag,
                    minBudget: tag.min_budget,
                    maxBudget,
                    description: (_a = tag.description) !== null && _a !== void 0 ? _a : '',
                });
        }
    };
    const handleMinBudgetChange = (minBudget) => {
        var _a;
        if (minBudget !== null) {
            !!functions.handleServiceChange &&
                functions.handleServiceChange({
                    service: tag.tag,
                    minBudget,
                    maxBudget: tag.max_budget,
                    description: (_a = tag.description) !== null && _a !== void 0 ? _a : '',
                });
        }
    };
    return (_jsx(Div, { style: { width: '100%', marginTop: '8px', marginBottom: '8px' }, children: _jsx(Div, { flex: 'row', justify: 'space-between', tablet: { flex: 'column', align: 'flex-start' }, children: _jsxs(Div, { flex: 'row', align: 'center', gap: 32, tablet: { flex: 'column', align: 'flex-start' }, children: [_jsx(Breadcrumb, { style: { width: '70%' }, items: [
                            { title: t(`tags|${tag.category}`) },
                            { title: t(`tags|${tag.label}`) },
                        ] }), _jsx(Div, { flex: 'row', align: 'flex-end', tablet: { flex: 'column', align: 'flex-start' }, children: _jsx(Typography.Paragraph, { editable: {
                                onChange: handleDescriptionChange,
                                maxLength: 200,
                                autoSize: { minRows: 1, maxRows: 1 },
                            }, style: {
                                width: '100%',
                            }, className: "body2", children: tag.description || (_jsx("span", { style: { color: theme.colors.grey_3 }, children: t('provider-profile|expertise.edit.service_description') })) }) }), _jsxs(Div, { fitContent: true, flex: "row", align: 'center', children: [_jsx(InputNumber, { style: { marginRight: 16, width: 125 }, defaultValue: tag.min_budget, value: tag.min_budget, formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), onChange: handleMinBudgetChange, min: 0, max: 2000000000 }), isChecked ? (_jsx(Checkbox, { style: { marginRight: 16, width: 125 }, checked: isChecked, onClick: handleCheck, className: 'service-budget-checkbox', children: t('provider-profile|expertise.service.unlimited') })) : (_jsx(InputNumber, { style: { marginRight: 16, width: 125 }, defaultValue: tag.max_budget, value: tag.max_budget, formatter: (value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','), onChange: handleMaxBudgetChange, min: 0, max: 2000000000 })), _jsx(Delete, { width: 16, height: 16, style: { color: theme.colors.red_1, cursor: 'pointer' }, onClick: () => !!functions.toggleServiceTag &&
                                    functions.toggleServiceTag({ service: tag.tag }) })] })] }) }) }));
};
export default ServiceBudgetSelectionRow;
