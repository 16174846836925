import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Edit = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.9825 6.48281L24.2318 4.23215C24.7007 3.76324 25.3367 3.49982 25.9998 3.49982C26.663 3.49982 27.2989 3.76324 27.7678 4.23215C28.2367 4.70105 28.5002 5.33702 28.5002 6.00015C28.5002 6.66327 28.2367 7.29924 27.7678 7.76815L13.6092 21.9268C12.9043 22.6313 12.035 23.1491 11.0798 23.4335L7.49985 24.5001L8.56651 20.9201C8.85088 19.965 9.36869 19.0957 10.0732 18.3908L21.9825 6.48281ZM21.9825 6.48281L25.4998 10.0001M23.4998 19.1668V25.5001C23.4998 26.2958 23.1838 27.0589 22.6212 27.6215C22.0586 28.1841 21.2955 28.5001 20.4998 28.5001H6.49985C5.7042 28.5001 4.94114 28.1841 4.37853 27.6215C3.81592 27.0589 3.49985 26.2958 3.49985 25.5001V11.5001C3.49985 10.7045 3.81592 9.94143 4.37853 9.37883C4.94114 8.81622 5.7042 8.50015 6.49985 8.50015H12.8332"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Edit;
