import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import React from 'react';
import RfpDescriptionType from '../../../../features/enums/rfp-description-type';
import parse, { attributesToProps, domToReact, } from '../../../../features/helpers/parse';
import { useTranslation } from '../../../../plugins/i18n';
import { useTheme } from '../../../../plugins/styled';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import RfpSingleTile from './rfp-single-tile';
const RfpDescription = ({ rfp, isPartner, rfpManager, }) => {
    const { t } = useTranslation();
    const theme = useTheme();
    const options = {
        replace: (domNode) => {
            if (domNode.attribs) {
                const props = attributesToProps(domNode.attribs);
                if (domNode.name === 'ul') {
                    return (_jsx(Typography, { elementTheme: "body2", children: _jsx("ul", Object.assign({}, props, { children: domToReact(domNode.children) })) }));
                }
                if (domNode.name === 'h4') {
                    return (_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textTransform: "uppercase", style: { letterSpacing: 0.3 }, bold: true, children: domToReact(domNode.children) }), _jsx(Divider, { style: { margin: 0 } })] }));
                }
            }
        },
    };
    return (_jsx(RfpSingleTile, { children: _jsxs(Typography, { elementTheme: "body2", color: theme.colors.primary, children: [isPartner && (_jsxs(_Fragment, { children: [rfp.special_conditions &&
                            rfp.special_conditions !== '<p><br></p>' && (_jsxs(Div, { flex: "column", gap: 12, children: [_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textTransform: "uppercase", style: { letterSpacing: 0.3 }, bold: true, children: t(`rfp|rfpPage.specialConditions`) }), _jsx(Divider, { style: { margin: 0 } })] }), _jsx(Typography, { elementTheme: "body2", children: parse(rfp.special_conditions) })] })), rfp.additional_comments &&
                            parse(rfp.additional_comments) !== '' && (_jsxs(Div, { flex: "column", gap: 12, children: [_jsxs(Div, { flex: "column", gap: 6, children: [_jsx(Typography, { elementTheme: "caption", color: theme.colors.grey_3, textTransform: "uppercase", style: { letterSpacing: 0.3 }, bold: true, children: t(`rfp|rfpPage.additionalComments`) }), _jsx(Divider, { style: { margin: 0 } })] }), _jsx(Typography, { elementTheme: "body2", children: parse(rfp.additional_comments) })] }))] })), !isPartner && rfp.status === 'PendingApproval' ? (_jsx(Div, { flex: "column", gap: 12, children: rfp.original_description
                        ? parse(rfp.original_description, options)
                        : '' })) : rfp.description ? (_jsxs(Div, { children: [" ", parse(rfp.description, options), " "] })) : (rfp.description_container && (_jsx(Div, { flex: "column", gap: 12, children: React.Children.toArray(Object.values(RfpDescriptionType).map((descriptionType) => {
                        var _a, _b;
                        if ((_a = rfp.description_container) === null || _a === void 0 ? void 0 : _a[descriptionType]) {
                            return (_jsxs(React.Fragment, { children: [_jsx(Div, { bordersSide: "bottom", borderColor: theme.colors.grey_5, children: _jsx(Typography, { className: "hd-mb-05", style: { letterSpacing: 0.3 }, elementTheme: "caption", bold: true, color: theme.colors.grey_3, children: t(`rfp|rfpPage.rfpDescriptionType.${descriptionType}`) }) }), _jsx(Div, { children: parse((_b = rfp.description_container) === null || _b === void 0 ? void 0 : _b[descriptionType], options) })] }, descriptionType));
                        }
                        return null;
                    })) })))] }) }));
};
export default RfpDescription;
