import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import HdLogo from '@hellodarwin/icons/dist/icons/HdLogo';
import useLocale from '../../../features/providers/locale-provider';
import theme from '../../../theme';
import Button from '../../common/button';
import Div from '../../common/div';
const MobileOnboardingSider = ({ appType }) => {
    const { selectedLocale, changeLanguage } = useLocale();
    const handleLangChange = () => {
        const otherLang = selectedLocale === 'fr' ? 'en' : 'fr';
        changeLanguage(otherLang);
    };
    return (_jsxs(Div, { flex: 'column', gap: 16, children: [_jsx(Div, { flex: 'row', justify: 'center', align: "center", backgroundColor: appType === 'client' ? theme.colors.white_1 : theme.colors.grey_1, style: { height: 100, width: '100%' }, children: _jsx(HdLogo, { backgroundType: "dark", type: "primary", color: "b/w" }) }), _jsx(Div, { flex: 'row', position: "absolute", justify: "flex-end", children: _jsx(Button, { fitContent: true, transparent: true, onClick: handleLangChange, children: selectedLocale === 'fr' ? 'EN' : 'FR' }) })] }));
};
export default MobileOnboardingSider;
