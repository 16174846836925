import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Partition = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.54176 16.0054H16.3218M16.3218 16.0054V6.08202H22.0291M16.3218 16.0054V25.918H22.4582M2 12.0682H9.54176V19.9318H2V12.0682ZM22.4582 2.15558H30V10.0192H22.4582V2.15558ZM22.4582 21.9809H30V29.8445H22.4582V21.9809Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Partition;
