import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import parse from '../../features/helpers/parse';
import Div from '../common/div';
import Typography from '../common/typography';
const FaqBlock = ({ qa }) => {
    var _a;
    return (_jsxs(Div, { flex: "column", children: [typeof qa.answer === 'string' ? (_jsx(Typography, { elementTheme: "body2", children: parse(qa.answer) })) : (_jsx(Typography, { elementTheme: "body2", children: qa.answer })), qa.videos && (_jsx(Div, { flex: "column", gap: 16, style: { paddingTop: '1rem', paddingLeft: '2rem' }, children: React.Children.toArray((_a = qa.videos) === null || _a === void 0 ? void 0 : _a.map((v, i) => (_jsxs(Div, { children: [_jsx(Typography, { elementTheme: "body2", bold: true, children: v.label }), _jsx("div", { className: "iframe-container", children: _jsx("iframe", { title: v.name, src: v.url, className: "iframe", allowFullScreen: true }) })] }, i)))) }))] }));
};
export default FaqBlock;
