var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import { useMemo } from 'react';
import getAverageAllReviews from '../../../../features/helpers/get-average-reviews';
import { getStringDateFromString } from '../../../../features/helpers/get-formatted-date';
import useLocale from '../../../../features/providers/locale-provider';
import { useTheme } from '../../../../plugins/styled';
import Avatar from '../../../common/avatar';
import Div from '../../../common/div';
import Typography from '../../../common/typography';
import ReviewRatings from '../../../reviews/ratings';
import { MessageCardContainer } from './styles';
const MessageCard = (_a) => {
    var { actions, contactMessage, clientMessage, messagedFirst } = _a, contact = __rest(_a, ["actions", "contactMessage", "clientMessage", "messagedFirst"]);
    const theme = useTheme();
    const ContactInformations = ({ reviews, contactName, sent_at, city, province, }) => {
        const { selectedLocale } = useLocale();
        const average = useMemo(() => {
            return reviews ? getAverageAllReviews(reviews) : undefined;
        }, [reviews]);
        return (_jsxs(Div, { flex: "row", gap: 24, align: "center", justify: "space-between", tablet: {
                flex: 'column',
                align: 'center',
            }, children: [_jsxs(Div, { flex: "column", gap: 8, children: [_jsx(Typography, { elementTheme: "subtitle2", nowrap: true, ellipsis: true, tablet: { textAlign: 'center' }, children: contactName }), (!!city || average || reviews) && (_jsxs(Div, { flex: "row", align: "center", gap: 10, tablet: { flex: 'column', align: 'center' }, children: [!!city && (_jsx(Typography, { elementTheme: "overline", color: theme.colors.grey_2, fitContent: true, tablet: { textAlign: 'center' }, children: `${city}, ${province}` })), !!city && average && reviews && (_jsx(Divider, { type: "vertical", style: {
                                        margin: 0,
                                        height: '15px',
                                        top: 0,
                                        borderColor: theme.colors.grey_4,
                                    } })), average && reviews && (_jsx(ReviewRatings, { size: "extra-small", showOne: true, defaultValue: Math.round(average * 10) / 10, showValue: true, textPlacement: "end", allowHalf: true }))] }))] }), !!sent_at && (_jsx(Typography, { elementTheme: "body3", color: theme.colors.grey_2, nowrap: true, children: getStringDateFromString(sent_at || '', selectedLocale) }))] }));
    };
    const MessageInfo = ({ message, isClient, }) => {
        return (_jsx("div", { style: {
                paddingLeft: isClient ? 24 : 0,
                paddingRight: !isClient ? 24 : 0,
            }, children: _jsx(Div, { backgroundColor: isClient ? theme.colors.green_4 : theme.colors.beige_3, borderColor: isClient ? theme.colors.green_3 : theme.colors.beige_2, style: {
                    padding: '8px 16px',
                }, children: _jsx(Typography, { elementTheme: "body2", children: message }) }) }));
    };
    return (_jsxs(MessageCardContainer, { children: [_jsx(Avatar, { src: contact.logo, size: 46, shape: "square", children: contact.contactName[0] }), _jsxs(Div, { flex: "column", gap: 16, tablet: { align: 'center' }, children: [_jsx(ContactInformations, Object.assign({}, contact)), _jsxs(Div, { flex: messagedFirst === 'client' ? 'column-reverse' : 'column', gap: 16, tablet: { align: 'center' }, children: [contactMessage && _jsx(MessageInfo, { message: contactMessage }), clientMessage && _jsx(MessageInfo, { message: clientMessage, isClient: true })] }), !!actions && _jsx("div", { style: { width: 'fit-content' }, children: actions })] })] }));
};
export default MessageCard;
