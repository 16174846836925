import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { createPortal } from 'react-dom';
import { useMediaQuery } from 'react-responsive';
import { useTranslations } from '../../../../features/providers/translations-provider';
import { useTheme } from '../../../../plugins/styled';
import Button from '../../../common/button';
import Div from '../../../common/div';
import { TabletSaveContainer } from './styles';
const ProfileFormFooterContent = ({ handleFinish, handleReset, isSmall, }) => {
    const { t } = useTranslations();
    const theme = useTheme();
    return (_jsxs(Div, { flex: "row", gap: 24, align: "center", justify: "flex-end", tablet: {
            flex: 'column-reverse',
            align: 'center',
            justify: 'center',
            gap: 8,
            style: { width: '100%' },
        }, fitContent: isSmall, children: [!!handleReset && (_jsx(Button, { onClick: handleReset, size: 'small', defaultStyle: theme.colors.white_1, tablet: { style: { width: '100%' } }, children: t('button|cancel') })), _jsx(Button, { onClick: handleFinish, size: 'small', tablet: { style: { width: '100%' } }, children: t('button|save') })] }));
};
const ProfileFormFooter = (props) => {
    const theme = useTheme();
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    return isTablet ? (createPortal(_jsx(TabletSaveContainer, { children: _jsx(ProfileFormFooterContent, Object.assign({}, props)) }), document.getElementById('root'))) : (_jsx(ProfileFormFooterContent, Object.assign({}, props)));
};
export default ProfileFormFooter;
