import {
  AssetEntity,
  AssetFolderEntity,
  AvailableRfp,
  BillingSession,
  CategoryContentLabelsByName,
  Company,
  CompanyAsset,
  Contact,
  Industry,
  Invoice,
  Match,
  Portfolio,
  Provider,
  ProviderProfile,
  PurchasedRfp,
  RaiseHandParams,
  ReviewResult,
  ServiceTag,
  Tag,
  Tags,
  TeamMember,
  TeamMemberItem,
} from '@hellodarwin/core/lib/features/entities';
import {
  PartnerRfpRequest,
  Rfp,
  RfpAsset,
} from '@hellodarwin/core/lib/features/entities/rfp-entities';
import { UTMParamsType } from '@hellodarwin/core/lib/features/providers/utm-provider';
import { Token } from '@stripe/stripe-js';
import axios, { AxiosInstance } from 'axios';

export default class PartnerApiClient {
  private readonly baseURL: string;
  private readonly accessToken: string;

  constructor(baseUrl: string, accessToken: string) {
    this.baseURL = baseUrl;
    this.accessToken = accessToken;
  }

  createAxios = (contentType = 'application/json'): AxiosInstance => {
    return axios.create({
      baseURL: this.baseURL + '/partner',
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  };
  createAxiosWidget = (contentType = 'application/json'): AxiosInstance => {
    return axios.create({
      baseURL: this.baseURL,
      headers: {
        'Content-Type': contentType,
        Authorization: `Bearer ${this.accessToken}`,
      },
    });
  };

  hello = async (
    promocode: string | null,
    utmParams: UTMParamsType | null,
  ): Promise<Provider> => {
    const response = await this.createAxios().post('/hello', {
      promocode: promocode,
      utm: utmParams,
    });

    return response.data;
  };

  updateProfile = async (data: FormData): Promise<Provider> => {
    const response = await this.createAxios('multipart/form-data').put(
      '/v2/profile',
      data,
    );

    return response.data;
  };

  fetchProfile = async (): Promise<Provider> => {
    const response = await this.createAxios().get('/profile');

    return response.data;
  };
  fetchInfoForWidget = async (providerID: string): Promise<Provider> => {
    const response = await this.createAxiosWidget().get(
      `/widget/${providerID}`,
    );

    return response.data;
  };

  fetchAvailableMatches = async (): Promise<Match[]> => {
    const response =
      await this.createAxios().get<Match[]>('/matches/available');

    return response.data;
  };

  fetchPurchasedMatches = async (): Promise<Match[]> => {
    const response =
      await this.createAxios().get<Match[]>('/matches/purchased');

    return response.data;
  };

  fetchIgnoredMatches = async (): Promise<Match[]> => {
    const response = await this.createAxios().get<Match[]>('/matches/ignored');

    return response.data;
  };

  fetchWonRfp = async (): Promise<Rfp[]> => {
    const response = await this.createAxios().get<Rfp[]>(`/rfp/won`);
    return response.data;
  };

  fetchMatch = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().get<Match>('/matches/' + matchId);

    return response.data;
  };

  fetchContactById = async (contactId: string): Promise<Contact> => {
    const response = await this.createAxios().get('/contacts/' + contactId);

    return response.data;
  };

  ignoreRfp = async (
    matchId: string,
    refuseReason: string,
    refuseReasonSpecified: string,
  ): Promise<Match> => {
    const response = await this.createAxios().put<Match>(
      '/matchmaking/' + matchId + '/ignore',
      {
        refused_reason: refuseReason,
        refused_reason_specified: refuseReasonSpecified,
      },
    );

    return response.data;
  };

  unIgnoreRfp = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put<Match>(
      '/matchmaking/' + matchId + '/unIgnore',
    );

    return response.data;
  };

  archiveRfp = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put<Match>(
      '/matchmaking/' + matchId + '/archive',
    );

    return response.data;
  };

  unarchiveRfp = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put<Match>(
      '/matchmaking/' + matchId + '/unarchive',
    );

    return response.data;
  };

  createTestUnsoldRfps = async (): Promise<AvailableRfp[]> => {
    const response =
      await this.createAxios().post<AvailableRfp[]>('/test/rfp/unsold');

    return response.data;
  };

  purchaseRfp = async (
    rfpId: string,
    matchId: string,
    token: Token | undefined,
  ): Promise<PurchasedRfp> => {
    const data = {
      token: token ?? null,
    };

    const response = await this.createAxios().post<PurchasedRfp>(
      '/rfp/' + rfpId + '/matches/' + matchId + '/purchase',
      data,
    );

    return response.data;
  };

  getTags = async (): Promise<Tags> => {
    const response = await this.createAxios().get('/tags');

    return response.data;
  };

  getCategoryTags = async (): Promise<Tag[]> => {
    const response = await this.createAxios().get('/tags/categories');

    return response.data;
  };

  fetchLocalizedCategoriesWithSelectedTags =
    async (additionalTags?: string[]): Promise<CategoryContentLabelsByName> => {
      let url = '/tags/categories/selected';
      if (additionalTags && additionalTags.length > 0) {
        url += `?additional_tags=${additionalTags.join(",")}`;
      }
      const response = await this.createAxios().get(url);
      return response.data;
    };

  fetchIndustries = async (): Promise<Industry[]> => {
    const response =
      await this.createAxios().get<Industry[]>(`/tags/industries`);

    return response.data;
  };

  createTags = async (
    serviceTags: ServiceTag[],
    specialtyTags: string[],
  ): Promise<string> => {
    const data = {
      service_tags: serviceTags,
      specialty_tags: specialtyTags,
    };

    const response = await this.createAxios().post<string>(
      '/tags/create',
      data,
    );

    return response.data;
  };

  onboard = async (): Promise<string> => {
    const response = await this.createAxios().post<string>('/onboard');

    return response.data;
  };

  onboardingMatch = async (): Promise<string> => {
    const response = await this.createAxios().post<string>('/onboard/matches');

    return response.data;
  };

  fetchCreditBalance = async (): Promise<number> => {
    const response = await this.createAxios().get<number>('/balance');

    return response.data;
  };

  fetchBillingSession = async (): Promise<BillingSession> => {
    const returnUrl = window?.location?.href || '';
    const response = await this.createAxios().get(
      `/billing/session?returnUrl=${returnUrl}`,
    );

    return response.data;
  };

  fetchInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/invoice`,
    );

    return response.data;
  };

  fetchRaisehandInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/raisehandInvoice`,
    );

    return response.data;
  };

  fetchWinFeeInvoice = async (matchId: string): Promise<Invoice> => {
    const response = await this.createAxios().get(
      `/matchmaking/${matchId}/winFeeInvoice`,
    );
    return response.data;
  };

  submitReferralRfp = async (rfp: PartnerRfpRequest): Promise<Rfp> => {
    const response = await this.createAxios().post('/rfp/referral', rfp);

    return response.data;
  };

  fetchPortfolios = async (): Promise<Portfolio[]> => {
    const response = await this.createAxios().get('/portfolios');

    return response.data;
  };

  submitPortfolio = async (data: FormData): Promise<Portfolio> => {
    const response = await this.createAxios('multipart/form-data').post(
      '/portfolios',
      data,
    );

    return response.data;
  };

  removePortfolio = async (portfolioID: string) => {
    const response = await this.createAxios().delete(
      `/portfolios/${portfolioID}`,
    );

    return response.data;
  };

  updatePortfolio = async (data: FormData) => {
    const response = await this.createAxios('multipart/form-data').put(
      `/portfolios`,
      data,
    );

    return response.data;
  };

  fetchTeamMembers = async (): Promise<TeamMember[]> => {
    const response = await this.createAxios().get('/team/members');

    return response.data;
  };

  submitTeamMember = async (data: FormData): Promise<TeamMember> => {
    const response = await this.createAxios('multipart/form-data').post(
      '/team/members',
      data,
    );

    return response.data;
  };

  editTeamMember = async (
    teamMemberId: string,
    data: FormData,
  ): Promise<TeamMember> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/team/members/${teamMemberId}`,
      data,
    );

    return response.data;
  };

  updateTeamMemberOrder = async (
    teamMembers: TeamMemberItem[],
  ): Promise<TeamMember[]> => {
    const response = await this.createAxios().put(
      '/team/members/order',
      teamMembers,
    );
    return response.data;
  };

  removeTeamMember = async (teamMemberID: string): Promise<string> => {
    const response = await this.createAxios().delete(
      `/team/members/${teamMemberID}`,
    );

    return response.data;
  };

  raiseHand = async (params: RaiseHandParams): Promise<string> => {
    const response = await this.createAxios().put(
      `/matchmaking/raiseHand`,
      params,
    );

    return response.data;
  };

  fetchReviews = async (): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get('/reviews');
    return response.data;
  };

  fetchReviewsById = async (providerId: string): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(`/reviews/${providerId}`);

    return response.data;
  };
  fetchReviewsByIdForWidget = async (
    providerId: string,
  ): Promise<ReviewResult[]> => {
    const response = await this.createAxios().get(`/widget/${providerId}`);

    return response.data;
  };

  fetchAssets = async (rfpId: string): Promise<RfpAsset[]> => {
    const response = await this.createAxios().get(`/rfp/${rfpId}/assets`);

    return response.data;
  };

  fetchProfileAnalytics = async (
    providerId: string,
  ): Promise<ProviderProfile> => {
    const response = await this.createAxios().get(`/dashboard/profile`);

    return response.data;
  };

  updateBanner = async (
    companyId: string,
    data: FormData,
  ): Promise<Provider> => {
    const response = await this.createAxios(
      'multipart/form-data',
    ).put<Provider>(`/profile/${companyId}/banner`, data);
    return response.data;
  };

  deleteBanner = async (companyId: string): Promise<Provider> => {
    const response = await this.createAxios().delete<Provider>(
      `/profile/${companyId}/banner`,
    );
    return response.data;
  };

  updateVideos = async (
    companyId: string,
    data: FormData,
  ): Promise<CompanyAsset> => {
    const response = await this.createAxios(
      'multipart/form-data',
    ).put<CompanyAsset>(`/profile/${companyId}/videos`, data);
    return response.data;
  };

  deleteVideos = async (companyId: string): Promise<Provider> => {
    const response = await this.createAxios().delete<Provider>(
      `/profile/${companyId}/videos`,
    );
    return response.data;
  };

  updateLogo = async (companyId: string, data: FormData): Promise<Provider> => {
    const response = await this.createAxios(
      'multipart/form-data',
    ).put<Provider>(`/profile/${companyId}/picture`, data);
    return response.data;
  };

  deleteLogo = async (companyId: string): Promise<Provider> => {
    const response = await this.createAxios().delete<Provider>(
      `/profile/${companyId}/picture`,
    );
    return response.data;
  };

  fetchReferredRfps = async (): Promise<Rfp[]> => {
    const response = await this.createAxios().get(`/referredRfps`);

    return response.data;
  };

  fetchContact = async (): Promise<Contact> => {
    const response = await this.createAxios().get(`/contact`);
    return response.data;
  };

  updateContact = async (data: FormData): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/contact`,
      data,
    );
    return response.data;
  };
  updateContactById = async (
    contactId: string,
    data: FormData,
  ): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/users/${contactId}`,
      data,
    );
    return response.data;
  };

  fetchCompany = async (): Promise<Company> => {
    const response = await this.createAxios().get(`/company`);
    return response.data;
  };

  updateCompany = async (data: FormData): Promise<Company> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/company`,
      data,
    );
    return response.data;
  };

  fetchCompanyUsers = async (): Promise<Contact[]> => {
    const response = await this.createAxios().get<Contact[]>(`/users`);

    return response.data;
  };

  upsertCompanyUser = async (data: FormData): Promise<Contact> => {
    const response = await this.createAxios('multipart/form-data').put(
      `/users`,
      data,
    );
    return response.data;
  };

  deleteCompanyUser = async (contactID: string): Promise<string> => {
    const response = await this.createAxios().delete<string>(
      `/users/${contactID}`,
    );

    return response.data;
  };

  sendInvitationEmail = async (email: string): Promise<Contact> => {
    const response = await this.createAxios().post(
      `/company/invitationEmail/${email}`,
    );
    return response.data;
  };

  getContactByEmail = async (email: string): Promise<Contact> => {
    const response = await this.createAxios().get(`/contacts/email/${email}`);
    return response.data;
  };

  declareWinner = async (matchId: string): Promise<Match> => {
    const response = await this.createAxios().put<Match>(
      '/matchmaking/' + matchId + '/declareWinner',
    );

    return response.data;
  };

  queryContactFolders = async (): Promise<AssetFolderEntity[]> => {
    const response =
      await this.createAxios().get<AssetFolderEntity[]>(`/assets/folder`);
    return response.data;
  };

  queryContactAssets = async (): Promise<AssetEntity[]> => {
    const response = await this.createAxios().get<AssetEntity[]>(`/assets`);

    return response.data;
  };

  setAssetOpened = async (assetId: string): Promise<AssetEntity> => {
    const response = await this.createAxios().put(`/assets/${assetId}/opened`);

    return response.data;
  };

  uploadAsset = async (data: FormData): Promise<AssetEntity> => {
    const response = await this.createAxios(
      'multipart/form-data',
    ).post<AssetEntity>(`/assets`, data);

    return response.data;
  };
}
