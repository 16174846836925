import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const ArtificialIntelligence = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.5072 18.32H20.5267M5 28.1996H27M5 3.80042H27V24.0463H5V3.80042ZM10.7037 10.0586H12.3107V11.6656H10.7037V10.0586ZM19.7233 10.0586H21.3302V11.6656H19.7233V10.0586Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default ArtificialIntelligence;
