import {
  Company,
  CompanyInitialState,
  Contact,
  ContactInitialState,
  Provider,
  ProviderInitialState,
} from '@hellodarwin/core/lib/features/entities';
import CoreFeatured from '@hellodarwin/core/lib/features/featured-flags';
import useDebounceFunction from '@hellodarwin/core/lib/features/hooks/use-debounce-function';
import { CompanyInfosProvider } from '@hellodarwin/core/lib/features/providers/company-infos-provider';
import { FeatureFlagProvider } from '@hellodarwin/core/lib/features/providers/featured-flags-provider';
import useLocale from '@hellodarwin/core/lib/features/providers/locale-provider';
import { useLogin } from '@hellodarwin/core/lib/features/providers/login-provider';
import useUTM from '@hellodarwin/core/lib/features/providers/utm-provider';
import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useAppDispatch, useAppSelector } from '../../app/app-hooks';
import PartnerFeatured from '../../featured';
import {
  hello,
  selectCompany,
  selectFormatedProfile,
  selectProfile,
  selectProviderContact,
} from '../api/slices/profile-slice';
import { usePartnerApi } from '../api/use-partner-api';

export interface UserContextType {
  provider: Provider;
  isLoggedIn: boolean;
  company: Company;
  contact: Contact;
  needOnBoarding: boolean;
}

export const UserContext = React.createContext<UserContextType>({
  provider: ProviderInitialState,
  isLoggedIn: false,
  company: CompanyInitialState,
  contact: ContactInitialState,
  needOnBoarding: false,
});

interface UserProviderProps extends PropsWithChildren {}

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const api = usePartnerApi();
  const dispatch = useAppDispatch();
  const utmParams = useUTM();

  const { accessToken } = useLogin();

  const provider = useAppSelector(selectProfile);
  const profile = useAppSelector(selectFormatedProfile);
  const contact = useAppSelector(selectProviderContact);

  const company = useAppSelector(selectCompany);
  const contacts = useMemo(() => provider.contacts, [provider]);
  const isLoggedIn = useMemo(() => !!provider.company_id?.length, [provider]);

  const needOnBoarding = useMemo(() => {
    if (!!provider) {
      return !provider.onboarded_at;
    }
    return false;
  }, [provider]);

  const { changeLanguage } = useLocale();

  const fetchProvider = useDebounceFunction(() => {
    const urlParams = new URLSearchParams(window.location.search);
    dispatch(
      hello({ api, sb_promocode: urlParams.get('promocode'), utm: utmParams }),
    );
  }, 500);

  useEffect(() => {
    if (accessToken === '') return;
    fetchProvider();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accessToken]);

  useEffect(() => {
    if (isLoggedIn && !!contact?.preferred_language) {
      changeLanguage(contact.preferred_language);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn]);

  useEffect(() => {
    if (contact?.email) {
      try {
        var _hsq = (window._hsq = window._hsq || []);
        _hsq?.push([
          'identify',
          {
            email: contact.email,
          },
        ]);
      } catch (e: any) {
        console.error(e);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contact?.email]);

  return (
    <UserContext.Provider
      value={{ provider, isLoggedIn, company, contact, needOnBoarding }}
    >
      <CompanyInfosProvider
        provider={provider}
        company={company}
        contacts={contacts}
        profile={profile}
      >
        <FeatureFlagProvider flags={{ ...CoreFeatured, ...PartnerFeatured }}>
          {children}
        </FeatureFlagProvider>
      </CompanyInfosProvider>
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);

export default UserProvider;
