import { Fragment as _Fragment, jsx as _jsx } from "react/jsx-runtime";
import useMediaQuery from '../../../features/helpers/use-media-query';
import theme from '../../../theme';
import MobileOnboardingSider from './mobile-onboarding-sider';
const OnboardingSider = ({ appType }) => {
    const isTablet = useMediaQuery({ maxWidth: theme.breakpoints.tablet });
    if (!isTablet)
        return _jsx(_Fragment, {});
    return _jsx(MobileOnboardingSider, { appType: appType });
};
export default OnboardingSider;
