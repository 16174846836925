import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Form from 'antd/es/form';
import Select from 'antd/es/select';
import React from 'react';
import { useTranslation } from '../../../../../plugins/i18n';
import { ExpertiseHeader } from './expertise-form';
const CategorySelect = ({ selectedCategories, categoriesOptions, toggleCategory, }) => {
    const { t } = useTranslation();
    const options = React.Children.toArray(categoriesOptions.map((category) => (_jsx(Select.Option, { value: category, children: t(`tags|${category}`) }, category))));
    return (_jsxs("div", { children: [_jsx(ExpertiseHeader, { size: "medium", title: t(`provider-profile|expertise.category.title`), subtitle: t(`expertise|category.subtitle`) }), _jsx(Form.Item, { validateStatus: selectedCategories.length > 0 ? 'success' : 'error', help: selectedCategories.length > 0
                    ? null
                    : t('expertise|category.validation.error'), children: _jsx(Select, { mode: 'multiple', allowClear: true, placeholder: t(`expertise|category.select.placeholder`), value: selectedCategories, onSelect: toggleCategory, onDeselect: toggleCategory, defaultValue: selectedCategories, style: { width: '100%' }, size: "large", children: options }) })] }));
};
export default CategorySelect;
