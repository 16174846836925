import styled from '../../plugins/styled';
import Div from '../common/div';
export const FaqContentContainer = styled(Div) `
  .iframe {
    width: 100%;
    height: 460px;
    @media screen and (max-width: 1920px) {
      height: 360px;
    }
    @media screen and (max-width: 1250px) {
      height: 300px;
    }
    @media screen and (max-width: 1250px) {
      height: 250px;
    }
    @media screen and (max-width: 1080px) {
      height: 180px;
    }
    @media screen and (max-width: 650px) {
      height: 160px;
    }
  }
  .image {
    width: 90%;
    margin: 2rem auto;
    display: block;
  }
`;
