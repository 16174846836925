export var ErrorCode;
(function (ErrorCode) {
    ErrorCode[ErrorCode["default"] = 0] = "default";
    ErrorCode[ErrorCode["billingNoPaymentMethod"] = 11007] = "billingNoPaymentMethod";
    ErrorCode[ErrorCode["billingInsufficientFunds"] = 11011] = "billingInsufficientFunds";
    ErrorCode[ErrorCode["billingLostCard"] = 11012] = "billingLostCard";
    ErrorCode[ErrorCode["billingStolenCard"] = 11013] = "billingStolenCard";
    ErrorCode[ErrorCode["billingProcessingError"] = 11014] = "billingProcessingError";
    ErrorCode[ErrorCode["billingIncorrectCVC"] = 11015] = "billingIncorrectCVC";
    ErrorCode[ErrorCode["billingIncorrectZip"] = 11016] = "billingIncorrectZip";
    ErrorCode[ErrorCode["billingGenericCardDeclined"] = 11017] = "billingGenericCardDeclined";
    ErrorCode[ErrorCode["billingExpiredCard"] = 11018] = "billingExpiredCard";
    ErrorCode[ErrorCode["billingEmptyAddress"] = 11019] = "billingEmptyAddress";
})(ErrorCode || (ErrorCode = {}));
