import { jsx as _jsx } from "react/jsx-runtime";
import Collapse from '../common/Collapse';
import Typography from '../common/typography';
import FaqBlock from './faq-block';
import { FaqContentContainer } from './styles';
const FaqContent = ({ QAs, defaultActiveKey, }) => {
    return (_jsx(FaqContentContainer, { children: _jsx(Collapse, { gap: 10, accordion: true, items: QAs.map((value, i) => ({
                id: value.question,
                title: (_jsx(Typography.Title, { level: 3, elementTheme: "subtitle2", noMargin: true, children: value.question }, i)),
                children: _jsx(FaqBlock, { qa: value }),
                forceRender: true,
            })) }) }));
};
export default FaqContent;
