import Typography from '@hellodarwin/core/lib/components/common/typography';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import GreenCheck from '@hellodarwin/icons/dist/icons/GreenCheck';
import getCategoryIcon from '../../../features/helpers/get-category-icon';
import { CategoryContainer, CategoryIconContainer } from './styles';

type CategoryProps = {
  category: string;
  selected: boolean;
  onClick: (tag: string) => void;
};

const Category = ({ category, selected, onClick }: CategoryProps) => {
  const { t } = useTranslation();

  const Icon = getCategoryIcon(category);

  const handleClick = () => onClick(category);

  return (
    <CategoryContainer $selected={selected} onClick={handleClick}>
      {selected && (
        <GreenCheck
          size={24}
          color={theme.colors.white_1}
          bgColor={theme.colors.grey_1}
          style={{ position: 'absolute', top: 8, right: 8 }}
        />
      )}

      <CategoryIconContainer>
        <Icon size={32} />
      </CategoryIconContainer>
      <Typography elementTheme="body1" medium={selected} textAlign="center">
        {t(`tags|${category}`)}
      </Typography>
    </CategoryContainer>
  );
};

export default Category;
