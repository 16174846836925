var TrackingEvent;
(function (TrackingEvent) {
    TrackingEvent["ReferralProjectSubmitted"] = "Referral Project Submitted";
    TrackingEvent["ReferralLinkClicked"] = "Referral Link Clicked";
    TrackingEvent["ProjectClicked"] = "Project Clicked";
    TrackingEvent["ProjectSubmitClicked"] = "Project Submit Clicked";
    TrackingEvent["MeetingClicked"] = "Meeting Clicked";
    TrackingEvent["ProviderClicked"] = "Provider Clicked";
    TrackingEvent["ProviderContactClicked"] = "Provider Clicked";
    TrackingEvent["UpdatedPortfolio"] = "Updated Portfolio";
    TrackingEvent["UpdatedTeamMember"] = "Updated Team Member";
    TrackingEvent["UpdatedInformation"] = "Updated Information";
    TrackingEvent["FundicaClicked"] = "Fundica Link Clicked";
    TrackingEvent["WidgetSnippetCopied"] = "Widget Snippet Copied";
    TrackingEvent["GrantApplyNowClicked"] = "Grant Apply Now Click";
})(TrackingEvent || (TrackingEvent = {}));
export default TrackingEvent;
