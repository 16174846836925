import { ProfileEditModalFunctions } from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal';
import ProfileEditionProvider from '@hellodarwin/core/lib/features/providers/profile-edition-provider';
import { PropsWithChildren, useMemo } from 'react';
import { useAppDispatch, useAppSelector } from '../../app';
import {
  createTags,
  deleteBanner,
  deleteLogo,
  deleteVideos,
  editTeamMember,
  editTeamMemberOrder,
  fetchProfile,
  onboardingMatch,
  removePortfolio,
  removeTeamMember,
  selectProfileLoading,
  submitPortfolio,
  submitTeamMember,
  updateBanner,
  updateLogo,
  updatePortfolio,
  updateProfile,
  updateVideos,
} from '../api/slices/profile-slice';
import {
  handleServiceChange,
  toggleCategoryTag,
  toggleServiceTag,
  toggleSpecialtyTag,
} from '../api/slices/tags-slice';
import { usePartnerApi } from '../api/use-partner-api';

interface PartnerProfileEditionProviderProps extends PropsWithChildren {}

const PartnerProfileEditionProvider: React.FC<
  PartnerProfileEditionProviderProps
> = ({ children }) => {
  const api = usePartnerApi();
  const dispatch = useAppDispatch();

  const isLoading = useAppSelector(selectProfileLoading);

  const functions: ProfileEditModalFunctions = useMemo(
    () => ({
      isLoading: isLoading,
      fetchProfile: () => dispatch(fetchProfile({ api })),
      updateProfile: ({ data }) => dispatch(updateProfile({ api, data })),
      submitPortfolio: ({ data }) => dispatch(submitPortfolio({ api, data })),
      updatePortfolio: ({ data }) => dispatch(updatePortfolio({ api, data })),
      removePortfolio: ({ portfolioID }) =>
        dispatch(removePortfolio({ api, portfolioID })),
      updateBanner: ({ companyId, data }) =>
        dispatch(updateBanner({ api, data, companyId })),
      updateLogo: ({ companyId, data }) =>
        dispatch(updateLogo({ api, data, companyId })),
      updateVideos: ({ companyId, data }) =>
        dispatch(updateVideos({ api, data, companyId })),
      submitTeamMember: ({ data }) => dispatch(submitTeamMember({ api, data })),
      editTeamMember: ({ data, teamMemberId }) =>
        dispatch(editTeamMember({ api, data, teamMemberId })),
      removeTeamMember: ({ teamMemberID }) =>
        dispatch(removeTeamMember({ api, teamMemberID })),
      deleteBanner: ({ companyId }) =>
        dispatch(deleteBanner({ api, companyId })),
      deleteLogo: ({ companyId }) => dispatch(deleteLogo({ api, companyId })),
      deleteVideos: ({ companyId }) =>
        dispatch(deleteVideos({ api, companyId })),
      handleServiceChange: ({ service, minBudget, maxBudget, description }) =>
        dispatch(
          handleServiceChange({ service, minBudget, maxBudget, description }),
        ),
      createTags: ({ serviceTags, specialtyTags }) =>
        dispatch(createTags({ api, serviceTags, specialtyTags })),
      onboardingMatch: () => dispatch(onboardingMatch({ api })),
      toggleCategoryTag: (category: string) =>
        dispatch(toggleCategoryTag(category)),
      toggleServiceTag: ({ service }) =>
        dispatch(toggleServiceTag({ service })),
      toggleSpecialtyTag: ({ specialty }) =>
        dispatch(toggleSpecialtyTag({ specialty })),
      updateTeamMemberOrder: ({ teamMembers }) =>
        dispatch(editTeamMemberOrder({ api, teamMembers })),
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLoading],
  );

  return (
    <ProfileEditionProvider functions={functions}>
      {children}
    </ProfileEditionProvider>
  );
};

export default PartnerProfileEditionProvider;
