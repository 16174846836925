import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const Presentation = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.30769 2V19.5C3.30769 20.4283 3.67239 21.3185 4.32155 21.9749C4.97072 22.6313 5.85117 23 6.76923 23H10.2308M3.30769 2H1M3.30769 2H28.6923M10.2308 23H21.7692M10.2308 23L8.69231 27.6667M28.6923 2H31M28.6923 2V19.5C28.6923 20.4283 28.3276 21.3185 27.6784 21.9749C27.0293 22.6313 26.1488 23 25.2308 23H21.7692M21.7692 23L23.3077 27.6667M8.69231 27.6667H23.3077M8.69231 27.6667L7.92308 30M23.3077 27.6667L24.0769 30M9.07692 16L13.6923 11.3333L16.9969 14.6747C18.5495 12.4211 20.5725 10.5397 22.9231 9.16333"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        fill="none"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Presentation;
