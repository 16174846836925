import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Divider from 'antd/es/divider';
import React from 'react';
import { useTheme } from '../../../../../plugins/styled';
import Div from '../../../../common/div';
import Typography from '../../../../common/typography';
import RfpSingleTile from '../rfp-single-tile';
import { RfpSidebarSection } from './styles';
const RfpSidebar = ({ sections }) => {
    const theme = useTheme();
    const SidebarField = ({ Icon, label, value }) => {
        return (_jsxs(Div, { flex: "column", gap: 4, children: [_jsxs(Div, { flex: "row", align: "center", gap: 8, children: [!!Icon && (_jsx(Icon, { size: 16, style: { flexShrink: 0 }, color: theme.colors.grey_2 })), _jsx(Typography, { elementTheme: "overline", medium: true, color: theme.colors.grey_2, textTransform: "uppercase", children: label })] }), _jsx(Typography, { elementTheme: "body2", style: { paddingLeft: !!Icon ? 8 + 16 : undefined }, children: value })] }));
    };
    return (_jsx(RfpSingleTile, { children: _jsx(Div, { flex: "column", gap: 20, children: React.Children.toArray(sections.map((fields, i) => (_jsxs(React.Fragment, { children: [i > 0 && (_jsx(Divider, { style: { margin: 0, borderColor: theme.colors.grey_4 } })), _jsx(RfpSidebarSection, { flex: "column", gap: 20, children: React.Children.toArray(fields.map((field, j) => _jsx(SidebarField, Object.assign({}, field)))) })] }, `sidebar-section-${i}`)))) }) }));
};
export default RfpSidebar;
