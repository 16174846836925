import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import Typography from '../typography';
import { ProgressionContent, ProgressionInner } from './styles';
const HdProgression = ({ value, size, barSize = 6, outerBarSize = 20, progressColor, progressBarColor, outerColor, inversed, }) => {
    const [currentValue, setCurrentValue] = useState(value);
    const changeValue = (newValue) => {
        if (newValue > value) {
            setTimeout(() => changeValue(newValue - 1), 5);
        }
        else if (newValue < value) {
            setTimeout(() => changeValue(newValue + 1), 5);
        }
        setCurrentValue(newValue);
    };
    useEffect(() => {
        changeValue(currentValue);
    }, [value]);
    return (_jsxs(ProgressionContent, { style: { width: size, height: size }, "$outerColor": outerColor, "$progressBarColor": progressBarColor, "$progressColor": progressColor, "$inversed": inversed, "$barSize": barSize, "$outerBarSize": outerBarSize, "$progressValue": currentValue, children: [_jsx("progress", { value: currentValue, max: 100 }), _jsx(ProgressionInner, { "$outerColor": outerColor, "$barSize": barSize, "$outerBarSize": outerBarSize, children: _jsxs(Typography, { elementTheme: "h5", children: [currentValue, "%"] }) })] }));
};
export default HdProgression;
