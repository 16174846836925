import { IconNode } from '@hellodarwin/icons/dist/features/entities/general';
import ArtificialIntelligence from '@hellodarwin/icons/dist/icons/ArtificialIntelligence';
import Blocks from '@hellodarwin/icons/dist/icons/Blocks';
import BusinessDevelopment from '@hellodarwin/icons/dist/icons/BusinessDevelopment';
import ChatIcon from '@hellodarwin/icons/dist/icons/ChatIcon';
import DeploymentUnit from '@hellodarwin/icons/dist/icons/DeploymentUnit';
import Design from '@hellodarwin/icons/dist/icons/Design';
import Development from '@hellodarwin/icons/dist/icons/Development';
import EditOutlined from '@hellodarwin/icons/dist/icons/EditOutline';
import FunnelPlot from '@hellodarwin/icons/dist/icons/FunnelPlot';
import GridViewOutline from '@hellodarwin/icons/dist/icons/GridViewOutline';
import Help from '@hellodarwin/icons/dist/icons/Help';
import Language from '@hellodarwin/icons/dist/icons/Language';
import Launch from '@hellodarwin/icons/dist/icons/Launch';
import Marketing from '@hellodarwin/icons/dist/icons/Marketing';
import Partition from '@hellodarwin/icons/dist/icons/Partition';
import Presentation from '@hellodarwin/icons/dist/icons/Presentation';
import Propulsion from '@hellodarwin/icons/dist/icons/Propulsion';
import ServerStack from '@hellodarwin/icons/dist/icons/ServerStack';
import Stats from '@hellodarwin/icons/dist/icons/Stats';
import TeamSize from '@hellodarwin/icons/dist/icons/TeamSize';
import Transformation from '@hellodarwin/icons/dist/icons/Transformation';

const getCategoryIcon = (category: string) => {
  const icons: { [index: string]: IconNode } = {
    undefined: Help,
    'applications development': GridViewOutline,
    'business development': BusinessDevelopment,
    training: Presentation,
    'marketing strategy': Propulsion,
    'translation services': ChatIcon,
    copywriting: EditOutlined,
    'managing software': Partition,
    'printed marketing': Blocks,
    'digital marketing': FunnelPlot,
    'audiovisual production': Marketing,
    'augmented reality/vr': DeploymentUnit,
    'recruitment and human resources': TeamSize,
    'professional services': Launch,
    'it services': ServerStack,
    'artificial intelligence': ArtificialIntelligence,
    'website design/modification': Development,
    design: Design,
    logistics: Stats,
    'environmental services': Language,
    engineering: Transformation,
  };

  if (icons[category] === undefined) {
    return icons['undefined'];
  }

  return icons[category];
};

export default getCategoryIcon;
