import Container from '@hellodarwin/core/lib/components/common/container';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';

import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import OnboardingFooter from '@hellodarwin/core/lib/components/onboarding/footer';
import OnboardingHeader from '@hellodarwin/core/lib/components/onboarding/header';
import {
  OnBoardingSteps,
  useOnBoarding,
} from '@hellodarwin/core/lib/context/onboarding-provider';
import List from 'antd/es/list';
import {
  selectTags,
  toggleCategoryTag,
} from 'src/features/api/slices/tags-slice';
import { useAppDispatch, useAppSelector } from '../../../app/app-hooks';
import Category from '../../../components/expertise/category';

const PartnerCategoryPage = () => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const { categories, selectedCategories } = useAppSelector(selectTags);
  const { setCurrentStep } = useOnBoarding();

  const handleToggle = (tag: string) => {
    dispatch(toggleCategoryTag(tag));
  };

  return (
    <PageLayout
      app="provider"
      title={t('provider-profile|expertise.category.title')}
      subtitle={t('expertise|category.title')}
      breadcrumbs={[]}
    >
      <OnboardingHeader />

      <Container>
        <List
          dataSource={categories}
          renderItem={(category) => (
            <List.Item>
              <Category
                key={category.label}
                category={category.label}
                selected={category.isSelected}
                onClick={() => handleToggle(category.label)}
              />
            </List.Item>
          )}
          grid={{ xxl: 5, xl: 5, lg: 4, md: 3, sm: 2, gutter: [24, 8] }}
        />
      </Container>
      <OnboardingFooter
        handleNext={() => setCurrentStep(OnBoardingSteps.Services)}
        handlePrev={() => setCurrentStep(OnBoardingSteps.Contact)}
        nextDisabled={selectedCategories.length <= 0}
      />
    </PageLayout>
  );
};

export default PartnerCategoryPage;
