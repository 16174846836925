import { IconProps } from '../features/entities/general';
import useIconProps from '../utils/use-icon-props';
import useStrokeWidth from '../utils/use-stroke-width';

const FunnelPlot = (props: IconProps) => {
  const iconProps = useIconProps(props);
  const strokeWidth = useStrokeWidth(iconProps.width);

  return (
    <svg
      {...iconProps}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.64 19.115L19.57 18.995M5.76 11.595H26.24M16 2.005C20.29 2.005 24.49 2.365 28.57 3.055C29.4 3.195 30 3.915 30 4.755V6.375C30 6.835 29.91 7.285 29.73 7.715C29.55 8.135 29.3 8.525 28.97 8.855L20.52 17.305C20.19 17.625 19.94 18.015 19.76 18.445C19.58 18.865 19.49 19.325 19.49 19.785V24.335C19.49 24.985 19.31 25.625 18.97 26.175C18.63 26.725 18.14 27.175 17.56 27.465L12.5 29.995V19.775C12.5 19.315 12.41 18.865 12.23 18.435C12.05 18.015 11.8 17.625 11.47 17.295L3.03 8.86501C2.7 8.53501 2.45 8.155 2.27 7.725C2.09 7.305 2 6.84501 2 6.385V4.76501C2 3.92501 2.6 3.205 3.43 3.065C7.58 2.355 11.79 2.005 16 2.005Z"
        stroke="currentColor"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FunnelPlot;
