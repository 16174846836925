export default class PortfolioAssetsAdapter {
    constructor() {
        this.getSingleAsset = (allAssets, portfolio_id, asset_id) => {
            if (allAssets) {
                return allAssets[portfolio_id][asset_id];
            }
            return {};
        };
        this.addSingleAsset = (allAssets, portfolioAsset) => {
            var _a;
            if (allAssets) {
                allAssets[portfolioAsset.provider_portfolio_id][(_a = portfolioAsset.provider_portfolio_asset_id) !== null && _a !== void 0 ? _a : ''] = portfolioAsset;
            }
            return allAssets;
        };
        this.updateSingleAsset = (allAssets, portfolioAsset) => {
            var _a;
            if (portfolioAsset) {
                allAssets[portfolioAsset.provider_portfolio_id][(_a = portfolioAsset.provider_portfolio_asset_id) !== null && _a !== void 0 ? _a : ''] = portfolioAsset;
            }
            return allAssets;
        };
        this.removeSingleAsset = (allAssets, portfolioAsset) => {
            var _a;
            if (allAssets) {
                delete allAssets[portfolioAsset.provider_portfolio_id][(_a = portfolioAsset.provider_portfolio_asset_id) !== null && _a !== void 0 ? _a : ''];
            }
            return allAssets;
        };
        this.getPortfolioAssets = (allAssets, portfolio_id) => {
            if (allAssets) {
                return allAssets[portfolio_id];
            }
            return {};
        };
        this.selectAllPortfolioAssets = (portfolioAssets) => {
            if (portfolioAssets) {
                const assets = Object.values(portfolioAssets);
                return assets;
            }
            return [];
        };
        this.selectAll = (allAssets) => {
            if (allAssets) {
                const mappedAssets = {};
                Object.keys(allAssets).forEach((key) => {
                    mappedAssets[key] = this.selectAllPortfolioAssets(allAssets[key]);
                });
                return mappedAssets;
            }
            return {};
        };
        this.setAll = (mappedPortfoliosAssets, allAssets) => {
            mappedPortfoliosAssets = this.getInitialState();
            Object.keys(allAssets).forEach((key) => {
                const assets = allAssets[key];
                let mappedAssets = {};
                assets.forEach((a) => {
                    var _a;
                    mappedAssets[(_a = a.provider_portfolio_asset_id) !== null && _a !== void 0 ? _a : ''] = a;
                });
                mappedPortfoliosAssets[key] = mappedAssets;
            });
            return mappedPortfoliosAssets;
        };
        this.removeOne = (allAssets, id) => {
            if (allAssets) {
                delete allAssets[id];
            }
            return allAssets;
        };
        this.insertOne = (allAssets, portfolioAssets, portfolio_id) => {
            if (allAssets) {
                allAssets[portfolio_id] = portfolioAssets;
            }
            return allAssets;
        };
        this.updateOne = (allAssets, { id, changes }) => {
            if (allAssets) {
                allAssets[id] = changes;
            }
            return allAssets;
        };
        this.getInitialState = () => {
            return {};
        };
    }
}
export const createPortfolioAssetsAdapter = () => {
    return new PortfolioAssetsAdapter();
};
