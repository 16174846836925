import Button from '@hellodarwin/core/lib/components/common/button';
import Container from '@hellodarwin/core/lib/components/common/container';
import Div from '@hellodarwin/core/lib/components/common/div';
import PageLayout from '@hellodarwin/core/lib/components/common/layout/page-layout';
import ExpertiseForm from '@hellodarwin/core/lib/components/forms/prebuilt-forms/profile-edit-modal/expertise/expertise-form';
import ProfileExpertise from '@hellodarwin/core/lib/components/provider/profile/profile-expertise';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';
import theme from '@hellodarwin/core/lib/theme';
import notification from 'antd/es/notification';
import { useEffect, useState } from 'react';
import { fetchRfpTags, selectTags } from 'src/features/api/slices/tags-slice';
import { useAppDispatch, useAppSelector } from '../app/index';
import {
  createTags,
  fetchProfile,
  onboardingMatch,
  selectProfile,
} from '../features/api/slices/profile-slice';
import { usePartnerApi } from '../features/api/use-partner-api';

const ExpertisePage = () => {
  const dispatch = useAppDispatch();
  const api = usePartnerApi();
  const tags = useAppSelector(selectTags);
  const provider = useAppSelector(selectProfile);
  const { t } = useTranslation('common');
  const [isFormValid, setIsFormValid] = useState(false);

  useEffect(() => {
    const hasServices = tags.selectedServices.length > 0;
    const hasCategories = tags.selectedCategories.length > 0;
    const hasSpecialties = tags.selectedSpecialties.length > 0;
    setIsFormValid(hasServices && hasCategories && hasSpecialties);
  }, [
    tags.selectedServices,
    tags.selectedCategories,
    tags.selectedSpecialties,
  ]);

  useEffect(() => {
    dispatch(fetchRfpTags({ api }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSave = async () => {
    try {
      const serviceTags = tags.selectedServices;
      const specialtyTags = tags.selectedSpecialties.map((t) => t.tag);

      await dispatch(createTags({ api, serviceTags, specialtyTags }));
      await dispatch(fetchProfile({ api }));
      await dispatch(onboardingMatch({ api }));

      notification.success({ message: 'Expertises updated', placement: 'top' });
    } catch (e: any) {
      notification.error({
        message: 'Something went wrong.',
        placement: 'top',
      });
      console.error(e);
    }
  };

  return (
    <PageLayout
      title={'Expertise'}
      app="provider"
      breadcrumbs={[
        {
          breadcrumbName: t('navigation.home'),
          path: '/',
        },
        {
          breadcrumbName: t('navigation.profile'),
          path: '/profile',
        },
        {
          breadcrumbName: t('navigation.expertise'),
        },
      ]}
    >
      <Container>
        <ExpertiseForm selectedTags={tags} />
        <Div flex={'column'} align="flex-end">
          <Button
            fitContent
            size="small"
            defaultStyle={theme.colors.purple_1}
            onClick={handleSave}
            disabled={!isFormValid}
          >
            {t('button.save')}
          </Button>
        </Div>
      </Container>
      <ProfileExpertise provider={provider} id="expertise" />
    </PageLayout>
  );
};

export default ExpertisePage;
