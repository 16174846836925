import Div from '@hellodarwin/core/lib/components/common/div';
import HdTag from '@hellodarwin/core/lib/components/common/hd-tag';
import { useTranslation } from '@hellodarwin/core/lib/plugins/i18n';

type RfpSpecialtiesProps = {
  specialties: string[];
};

const RfpSpecialties = ({ specialties }: RfpSpecialtiesProps) => {
  const { t: translateTag } = useTranslation();
  if (!specialties) {
    return <></>;
  }

  return (
    <Div flex={'row'} fitContent gap={8} wrap={'wrap'}>
      {specialties.slice(0, 4).map((tag) => (
        <HdTag text={translateTag(`tags|${tag}`)} />
      ))}
      {specialties.length > 4 && <HdTag text={'...'} />}
    </Div>
  );
};

export default RfpSpecialties;
