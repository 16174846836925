import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import React, { useMemo } from 'react';
import { useTheme } from '../../plugins/styled';
import Button from '../common/button';
import Div from '../common/div';
import Dropdown from '../common/dropdown';
import Typography from '../common/typography';
const RfpActionsButton = ({ actions, isPrimary, isSecondary, isLoading, isBigger, }) => {
    const theme = useTheme();
    const dropdownMenuItems = useMemo(() => {
        return actions.secondaryActions.map((action) => {
            const item = {
                label: (_jsxs(Div, { flex: "row", onClick: action.action, align: "center", gap: 10, children: [_jsx(Typography, { color: theme.colors.grey_2, style: { lineHeight: 0 }, children: action.Icon }), _jsxs(Typography, { elementTheme: "caption", bold: true, color: theme.colors.grey_2, children: [action.beforeLabel, action.label] })] })),
                onClick: (e) => {
                    e.domEvent.stopPropagation();
                    e.domEvent.preventDefault();
                    action.action;
                },
                key: action.label,
            };
            return item;
        });
    }, [actions]);
    const secondaryButtons = () => {
        return (actions.secondaryActions.length > 0 && (_jsx(Dropdown, { items: dropdownMenuItems, dropdownRender: (menu) => {
                if (React.isValidElement(menu)) {
                    const node = menu;
                    return React.cloneElement(node, {
                        style: Object.assign(Object.assign({}, node.props.style), { border: `1px solid ${theme.colors.primary}` }),
                    });
                }
                return menu;
            }, placement: "bottomRight", cta: { size: 20 } })));
    };
    const primaryButtons = () => {
        return (actions.primaryAction.length > 0 &&
            (isPrimary ? (_jsx(Div, { flex: "row", style: { gap: '4px' }, children: React.Children.toArray(actions.primaryAction.map((act, i) => (_jsxs(Button, { onClick: act.action, loading: isLoading, size: isBigger ? 'medium' : 'extra-small', fitContent: true, defaultStyle: i === 0 && actions.primaryAction.length > 1
                        ? theme.colors.white_1
                        : isBigger
                            ? theme.colors.yellow_1
                            : theme.colors.primary, style: { flex: isBigger ? 'none' : 1 }, children: [act.beforeLabel, act.label] }, act.label)))) })) : (_jsx(Div, { flex: "row", gap: 8, align: "center", fitContent: true, children: React.Children.toArray(actions.primaryAction.map((act, index) => (_jsxs(Button, { onClick: act.action, loading: isLoading, defaultStyle: index === 1 ? theme.colors.purple_1 : theme.colors.yellow_1, style: { flex: 1 }, size: "extra-small", children: [act.beforeLabel, act.label] }, act.label)))) }))));
    };
    return (_jsx(_Fragment, { children: isPrimary ? (primaryButtons()) : isSecondary ? (secondaryButtons()) : (_jsxs(_Fragment, { children: [primaryButtons(), secondaryButtons()] })) }));
};
export default RfpActionsButton;
